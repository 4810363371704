import React, { createContext, useState } from 'react';

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
    const initialBookingDetails = {
        bookingFee: 0,
        bookingReferenceId: 0,
        bookingId: 0,
        bookingStatus: 'DRAFT', // DRAFT, CONFIRMED, ASSIGNED, COMPLETED, CANCELLED, REJECTED
        isBookingLogged: false,
        selectedVehicleId: null,
        flagForDistanceCalculation: false,
        pageDisplay: {
            currentTab: 0,
            nextTab: 1,
            stepIndicator: 0,
        },
        location: {
            pickupLocation: '',
            fromPlaceId: '',
            dropLocation: '',
            toPlaceId: '',
            fromLocationFlag: true,

            stopLocation: '',
            stopPlaceId: '',
            stopLocationFlag: false,
            flagForStopLocation2: false,

            stopLocation2: '',
            stopPlaceId2: '',
            stopLocationFlag2: false,
            flagForStopLocation3: false,

            stopLocation3: '',
            stopPlaceId3: '',
            stopLocationFlag3: false,
            flagForStopLocation4: false,

            stopLocation4: '',
            stopPlaceId4: '',
            stopLocationFlag4: false,
        },
        generalDetails: {
            bookingDate: '',
            bookingTime: '',
            passengersCount: 1,
            expectedEndTime: '',
            byTheHourCheck: false,
            numberOfHours: 0,
            creationDate: '',
            modifiedDate: '',
            specialInstructions: ''
        },
        distanceMatrix: {
            tollFees: '',
            changeFlag: true,
            distance: {
                text: '',
                value: 0,
                unit: 'km'
            },
            duration: {
                text: '',
                value: 0,
                unit: 'minutes'
            }
        },
        vehicleSelection: {
            id: '',
            type: '',
            name: ''
        },
        passengerDetails: {
            firstName: '',
            lastName: '',
            email: '',
            mobileNumber: '',
            countryCode: '+61',
            onBehalfCheck: false,
            bookingPerson: {
                firstName: '',
                lastName: '',
                email: '',
                mobileNumber: '',
                countryCode: '+61',
            }
        },
        paymentDetails: {
            amount: '',
            currency: 'AUD',
            paymentRefId: 0,
            paymentIntentId: 0,
            error: {
                code: '',
                description: ''
            }
        }
    };

    const [bookingDetails, setBookingDetails] = useState(initialBookingDetails);
    const [bookingErrors, setBookingErrors] = useState({});

    const clearBookingForm = () => {
        setBookingDetails(initialBookingDetails); // Reset booking details to initial state
        setBookingErrors({}); // Clear all errors
    };

    return (
        <BookingContext.Provider value={{ bookingDetails, setBookingDetails, bookingErrors, setBookingErrors, clearBookingForm }}>
            {children}
        </BookingContext.Provider>
    );

};
