import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import PrivacyPolicy from './privacy-policy';
import HeroPrivacyPolicy from './hero-privacy-policy';


function MainPrivacyPolicy() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroPrivacyPolicy />
            <PrivacyPolicy />
            <Footer />
        </div>
    );
}

export default MainPrivacyPolicy;