import React, { useState } from 'react';
import { getBookingByRefIdAndEmail } from '../../db/bookingDAO';
import { isNullOrUndefinedOrEmpty, isValidBookingReferenceId, isValidEmail } from '../common/utilfn';
import { convertTo12HourFormat } from '../common/utilfn';
import BookingStatus from '../enums/BookingStatus';

function ManageBooking() {

    let [bookingReferenceId, setBookingReferenceId] = useState('');
    let [bookingId, setBookingId] = useState('');
    const [emailId, setEmailId] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [bookingDetails, setBookingDetails] = useState(null);
    const [bookingDetailVisibleFlag, setBookingDetailVisibleFlag] = useState(false);
    const [message, setMessage] = useState('');
    const [invalidBookingMessage, setInvalidBookingMessage] = useState('');
    const [invalidEmailMessage, setInvalidEmailMessage] = useState('');
    const [invalidBookingInfoMessage, setInvalidBookingInfoMessage] = useState('');
    const [cancellationRequestMessage, setCancellationRequestMessage] = useState('');

    const setDefaultState = () => {
        setPaymentId('');
        setBookingDetails(null);
        setBookingDetailVisibleFlag(false);
        setMessage('');
        setInvalidBookingMessage('');
        setInvalidEmailMessage('');
        setInvalidBookingInfoMessage('');
    }
    const getBookingDetail = async (e) => {
        e.preventDefault();
        try {

            if (isNullOrUndefinedOrEmpty(bookingReferenceId) && isNullOrUndefinedOrEmpty(emailId)) {
                setDefaultState();
                setInvalidBookingMessage('Please provide a valid Booking Number.');
                setInvalidEmailMessage('Please provide a valid Email Id.');
                return;
            }

            if (isNullOrUndefinedOrEmpty(bookingReferenceId) || !isValidBookingReferenceId(bookingReferenceId)) {
                setDefaultState();
                setInvalidBookingMessage('Please provide a valid Booking Number.');
                return;
            }
            else if (isNullOrUndefinedOrEmpty(emailId) || !isValidEmail(emailId)) {
                setDefaultState();
                setInvalidEmailMessage('Please provide a valid Email Id.');
                return;
            }

            setBookingReferenceId(bookingReferenceId);

            // Fetch booking details based on bookingReferenceId and emailId
            let responseData = await getBookingByRefIdAndEmail(bookingReferenceId, emailId);
            if (!responseData || responseData.length == 0) {
                setInvalidBookingInfoMessage('Please provide a valid Booking Information.');
                return;
            }
            let bookingData = responseData[0];

            setBookingId(bookingData.id);
            setBookingDetails(bookingData); // Store the booking details in state

            console.log('responseData  --> ' + bookingData);
            setBookingDetailVisibleFlag(true);

            if (bookingData && bookingData.paymentDetails) {
                const paymentRefId = bookingData.paymentDetails.paymentRefId;
                setPaymentId(paymentRefId);
            }

        } catch (error) {
            console.error('Error while fetching booking details:', error);
        }
    };

    const cancelBooking = async (e) => {
        e.preventDefault();
        setCancellationRequestMessage(
            `Please call on this support number <a href="tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}">${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a> to cancel this booking.`
        );

    };

    return (
        <section className="wrapper bg-light">
            <div className="container pb-14 pb-md-16">

                <div className="row mb-5">
                    <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
                        <br /><br />
                        <h3 className="display-3 mb-2">Manage Booking</h3>
                        <p className="fs-15">Manage your booking in this page and for any support please call on this number <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a>
                        </p>
                    </div>
                </div>

                <div className="row gx-4">
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input id="form_email" type="email" name="email" className="form-control"
                                placeholder="jane.doe@example.com"
                                onChange={(e) => {
                                    setEmailId(e.target.value);
                                    setDefaultState();
                                }}
                                required />
                            <label htmlFor="form_email">Email *</label>
                            {invalidEmailMessage && (<>
                                <p><strong>*</strong> {invalidEmailMessage} </p>
                            </>)}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input id="form_bookingNumber" name="bookingNumber" className="form-control" placeholder="Booking Number"
                                onChange={(e) => {
                                    setBookingReferenceId(e.target.value);
                                    setDefaultState();
                                }}
                                required />
                            <label htmlFor="form_bookingNumber">Booking Number *</label>
                            {invalidBookingMessage && (<>
                                <p><strong>*</strong> {invalidBookingMessage} </p>
                            </>)}

                        </div>
                    </div>
                    {invalidBookingInfoMessage && (<>
                        <p><strong>*</strong> {invalidBookingInfoMessage} </p>
                    </>)}

                    <div className="col-12">
                        {/*    <Link to="/booking-detail" className="btn btn-secondary rounded btn-send mb-3"> Get Booking </Link> */}
                        <input type="submit" className="btn btn-secondary rounded btn-send mb-3" value="Get Booking" onClick={getBookingDetail} />
                        <p className="text-muted"><strong>*</strong> These fields are required.</p>

                    </div>
                </div>

                {bookingDetailVisibleFlag && (
                    <>
                        <div className="container">
                            <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">
                                <div className="col-12">
                                    <div className="form-heading">
                                        <h3>Booking Details</h3>
                                    </div>
                                </div>

                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                                        </div>
                                        <div className="w-100 align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Address</h5>
                                            {(() => {
                                                // List of stop locations and their flags
                                                const stops = [
                                                    { location: bookingDetails.location.stopLocation, flag: bookingDetails.location.stopLocationFlag, label: 'Stop 1' },
                                                    { location: bookingDetails.location.stopLocation2, flag: bookingDetails.location.stopLocationFlag2, label: 'Stop 2' },
                                                    { location: bookingDetails.location.stopLocation3, flag: bookingDetails.location.stopLocationFlag3, label: 'Stop 3' },
                                                    { location: bookingDetails.location.stopLocation4, flag: bookingDetails.location.stopLocationFlag4, label: 'Stop 4' }
                                                ];

                                                // Filter the stops that are active (i.e., flag is true)
                                                const activeStops = stops.filter(stop => stop.flag);

                                                return (
                                                    <div className="address-list">
                                                        {/* From location */}
                                                        <div className="d-flex justify-content-between">
                                                            <address>Pickup: <span className="text-muted">{bookingDetails.location.pickupLocation}</span></address>
                                                        </div>

                                                        {/* Show each stop in its own row */}
                                                        {activeStops.map((stop, idx) => (
                                                            <div className="d-flex justify-content-between" key={idx}>
                                                                <address>{stop.label}: <span className="text-muted">{stop.location}</span></address>
                                                            </div>
                                                        ))}

                                                        {/* To location */}
                                                        <div className="d-flex justify-content-between">
                                                            <address>Drop: <span className="text-muted">{bookingDetails.location.dropLocation}</span></address>
                                                        </div>
                                                    </div>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                        </div>
                                        <div className="align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Booking Status</h5>
                                            <div className="d-block">Status : <span className="text-muted">{bookingDetails.bookingStatus}</span></div>
                                            <div className="d-block">Booking Amount : <span className="text-muted">{bookingDetails.bookingFee}</span></div>
                                            {bookingDetails.refundAmount && bookingDetails.refundAmount > 0 && (
                                                <div className="d-block">
                                                    Refund Amount: <span className="text-muted">{bookingDetails.refundAmount}</span>
                                                </div>
                                            )}
                                            {bookingDetails.generalDetails.specialInstructions && (
                                                <div className="d-block">Booking Notes: <span className="text-muted">{bookingDetails.generalDetails.specialInstructions}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                        </div>
                                        <div className="align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Date & Time</h5>
                                            <div className="d-block">Date : <span className="text-muted">{bookingDetails.generalDetails.bookingDate}</span></div>
                                            <div className="d-block">Time : <span className="text-muted">{convertTo12HourFormat(bookingDetails.generalDetails.bookingTime, true)}</span></div>
                                            {bookingDetails.generalDetails.byTheHourCheck && (
                                                <div className="d-block">Booked for : <span className="text-muted">{bookingDetails.generalDetails.numberOfHours} hours</span></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                        </div>
                                        <div className="align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Passenger Details</h5>
                                            <div className="d-block">Name : <span className="text-muted">{bookingDetails.passengerDetails.firstName} {bookingDetails.passengerDetails.lastName}</span></div>
                                            <div className="d-block">Email : <span className="text-muted">{bookingDetails.passengerDetails.email}</span></div>
                                            <div className="d-block">Mobile : <span className="text-muted">{bookingDetails.passengerDetails.countryCode || '+61'} - {bookingDetails.passengerDetails.mobileNumber}</span></div>
                                        </div>
                                    </div>
                                </div>

                                {bookingDetails.passengerDetails.onBehalfCheck && (
                                    <div className="col-12 mb-4">
                                        <div className="d-flex flex-row">
                                            <div>
                                                <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                                            </div>
                                            <div className="align-self-start justify-content-start">
                                                <h5 className="mb-1 text-secondary">Booking Person Details</h5>
                                                <div className="d-block">Name : <span className="text-muted">{bookingDetails.passengerDetails.bookingPerson.firstName} {bookingDetails.passengerDetails.lastName}</span></div>
                                                <div className="d-block">Email : <span className="text-muted">{bookingDetails.passengerDetails.bookingPerson.email}</span></div>
                                                <div className="d-block">Mobile : <span className="text-muted">{bookingDetails.passengerDetails.bookingPerson.countryCode || '+61'} - {bookingDetails.passengerDetails.bookingPerson.mobileNumber}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="col-12 mb-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-taxi"></i> </div>
                                        </div>
                                        <div className="align-self-start justify-content-start">
                                            <h5 className="mb-1 text-secondary">Vehicle Details</h5>
                                            <div className="d-block">Vehicle : <span className="text-muted"> {bookingDetails.vehicleSelection.name} </span></div>
                                            <div className="d-block">Passengers : <span className="text-muted">{bookingDetails.generalDetails.passengersCount}</span></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <input type="submit" className="btn btn-secondary rounded btn-send mb-3" value="Request for cancellation" onClick={cancelBooking} />
                                    {cancellationRequestMessage && <div id="cancellationMessage">{cancellationRequestMessage}</div>}
                                </div>
                            </div>
                        </div>

                    </>
                )}

            </div>

        </section>
    );
}

export default ManageBooking;