import { React, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loadExternalScripts, removeExternalScripts } from './scripts';
import { useAuth } from '../../contexts/authContext';

function Menu() {

    const { currentUser } = useAuth();
    const { signOut } = useAuth();
    const navigate = useNavigate();


    const logoStyle = {
        width: 200,  // Use number for pixel value
        height: 100   // Use number for pixel value
    };


    useEffect(() => {
        loadExternalScripts();
        return () => {
            removeExternalScripts(); // Cleanup scripts when the component is unmounted
        };

    }, []);

    /*
    const handleClick = () => {
            // Define your onClick logic here ""
            window.nextPrev(-1);
    };
        */
    const handleSignOut = async () => {
        try {
            await signOut();
            navigate('/signin?resetFlag=false'); // Redirect to the sign-in page after signing out
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <header className="wrapper bg-light">
            <div className="bg-primary text-white fw-bold fs-14">
                <div
                    className="container py-2 d-md-flex flex-md-row align-items-center justify-content-center justify-content-md-end ">
                    {/* 
                    <div className="d-flex flex-row align-items-center me-4 d-none d-md-block">
                        <p className="mb-0"><Link to="/joinFleet" className="link-white hover">Join The Fleet</Link></p>
                    </div>

                    <div className="d-flex flex-row align-items-center me-4 d-none d-md-block">
                        <p className="mb-0"><Link to="/reviews" className="link-white hover">Reviews</Link></p>
                    </div>
                    */}
                    <div className="d-flex flex-row align-items-center me-4 d-none d-md-block">
                        {currentUser ? (
                            <p className="mb-0"><Link className="link-white hover" onClick={handleSignOut}>Sign out</Link></p>
                        ) : (
                            <p className="mb-0"><Link to="/signin?resetFlag=false" className="link-white hover">Sign in</Link></p>
                        )}
                    </div>

                    <div className="d-flex flex-row align-items-center text-center border rounded py-1 px-2">
                        <div className="icon text-white fs-14 me-2"> <i className="uil uil-phone"></i></div>
                        <p className="mb-0"><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></p>
                    </div>
                </div>

            </div>
            <nav className="navbar navbar-expand-xl center-nav transparent navbar-light">
                <div className="container flex-xl-row flex-nowrap align-items-center">
                    <div className="navbar-brand w-100">
                        <a href="./">
                            <img src="./assets/img/bcc_logo_trans.png" srcSet="./assets/img/bcc_logo_trans.png 2x" alt="" style={logoStyle} />
                        </a>
                    </div>
                    <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                        <div className="offcanvas-header d-xl-none">
                            <a href="./">
                                <img src="./assets/img/bcc_logo_trans.png" srcSet="./assets/img/bcc_logo_trans.png 2x" alt="" style={logoStyle} />
                            </a>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body ms-xl-auto d-flex flex-column h-100">
                            <ul className="navbar-nav">
                                {/*
                                    <li className="nav-item">
                                        <Link to="/transfers" className="nav-link"> Airport Transfers </Link>
                                    </li>

                                    
                                    <li className="nav-item">
                                        <Link to="/location" className="nav-link"> Locations </Link>
                                    </li>
                                */}
                                <li className="nav-item">
                                    <Link to="/service" className="nav-link"> Services </Link>
                                </li>
                                {/*
                                <li className="nav-item">
                                    <Link to="/fleet" className="nav-link"> Fleet </Link>
                                </li>
                                */}
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">About Us</a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><Link to="/about" className="dropdown-item"> About Us </Link>
                                        </li>
                                        <li className="nav-item"><Link to="/faqs" className="dropdown-item"> FAQs </Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    {/*<a className="nav-link" href="contact.html">Contact Us</a> */}
                                    <Link to="/contact" className="nav-link"> Contact Us </Link>
                                </li>
                            </ul>

                            {/* HamBurger Menu */}
                            <div className="offcanvas-footer d-xl-none">
                                <div>
                                    {/*
                                        <div className="d-flex flex-row align-items-center me-4 mb-2">
                                            <p className="mb-0"><a href="join-the-fleet.html" className="link-white hover">Join The Fleet_HM</a></p>
                                        </div>
                                        <div className="d-flex flex-row align-items-center me-4 mb-2">
                                            <p className="mb-0"><a href="reviews.html" className="link-white hover">Reviews</a></p>
                                        </div>
                                    */}
                                    <div className="d-flex flex-row align-items-center me-4 mb-2">
                                        {currentUser ? (
                                            <p className="mb-0"><Link className="link-white hover" onClick={handleSignOut}>Sign out</Link></p>
                                        ) : (
                                            <p className="mb-0"><Link to="/signin?resetFlag=false" className="link-white hover">Sign in</Link></p>
                                        )}
                                    </div>
                                    <div className="d-flex flex-row align-items-center border rounded py-1 px-2">
                                        <div className="icon text-white fs-14 me-2"> <i className="uil uil-phone"></i></div>
                                        <p className="mb-0"><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-other d-flex">
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item">
                                <Link to="/booking" className="btn btn-sm btn-outline-secondary rounded">Book Now</Link>
                            </li>
                            <li className="nav-item d-block d-xl-none">
                                <button className="hamburger offcanvas-nav-btn"><span></span></button>
                            </li>
                        </ul>
                    </div>
                </div>
                {/*
                <div className="container form-navigation d-none">
                    <ul id="progressbar">
                        <li className="stepIndicator active" id="General" onClick={handleClick}><strong>General Details</strong>
                            <span className="bar-line"></span>
                        </li>
                        <li className="stepIndicator" id="Vehicle" onClick={handleClick}><strong>Vehicle Selection</strong>
                            <span className="bar-line"></span>
                        </li>
                        <li className="stepIndicator" id="Passenger" onClick={handleClick}><strong>Passenger Details</strong>
                            <span className="bar-line"></span>
                        </li>
                        <li className="stepIndicator" id="Summary" onClick={handleClick}><strong>Summary</strong>
                            <span className="bar-line"></span>
                        </li>
                        <li className="stepIndicator" id="Payment" onClick={handleClick}><strong>Payment</strong></li>
                    </ul>
                </div>
    */}
            </nav>
        </header>
    );
}

export default Menu;