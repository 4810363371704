import React from 'react';

const CancellationPolicy = () => {

  const policies = [
    "Cancellations made with less than 1 hour's notice before the vehicle dispatch for Sedans, SUVs, or People Movers within the metropolitan area.",
    "Cancellations with less than 24 hours’ notice for minibuses, buses, or coaches (8 seats and above).",
    "Any cancellation of a Stretch Limousine booking at any time after it has been confirmed.",
    "Bookings where passengers do not arrive or fail to contact the chauffeur or the local Business Class Chauffeur Customer Service Centre."
  ];

  return (

    <div className="container">
      <p />
      <h1>Cancellation Policy</h1>
      <p>Full cancellation charges* will apply when:</p>
      <ul>
        {policies.map((policy, index) => (
          <li key={index}>{policy}</li>
        ))}
      </ul>
      <p>
        *Full cancellation charges refer to the total booking value, excluding any additional miscellaneous fees. A Special Cancellation Policy applies to Weddings and Events.
      </p>
    </div>
  );
};

 
export default CancellationPolicy;