import React, { useState }  from 'react';
import { Modal, Button } from 'react-bootstrap';

function AppDownload() {
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleClick = (store) => {
        setMessage(`The ${store} is currently under development and will be available soon!`);
        setShowModal(true); // Show the modal
    };

    const handleClose = () => setShowModal(false);
    
    return (
        <section className="wrapper bg-white">
            <div className="container py-lg-20 pt-12 pb-22 position-relative">
                <div className="row gx-0 align-items-center">
                    <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 mt-lg-n2 text-center text-lg-start order-2 order-lg-0"
                        data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-dark mb-4">App Store</h2>
                        <h1 className="display-2 mb-6 mx-sm-n2 mx-md-0">Download The App Today</h1>
                        <p className="fs-15 mb-6 px-md-7 px-lg-0"></p>
                        {/*
                        <p className="fs-15 mb-6 px-md-7 px-lg-0">Book Vehicles Instantly, Charge Them To Your Credit Card And Track
                            Your Driver By GPS.</p>
                        */}
                        <div className="d-flex flex-row justify-content-lg-start justify-content-center gap-1" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                            <a href="#" onClick={() => handleClick('App Store')}>
                                <img src="./assets/img/photos/button-appstore.svg" className="w-100 h-11 rounded" alt="" />
                            </a>
                            <a href="#" onClick={() => handleClick('Google Play Store')}>
                                <img src="./assets/img/photos/button-google-play.svg" className="w-100 h-11 rounded" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                
                <img className="position-absolute app-image col-6 col-sm-6 col-md-5" src="./assets/img/photos/device.png"
                    srcSet="./assets/img/photos/device@2x.png 2x" data-cue="fadeIn" data-delay={process.env.REACT_APP_DELAY} alt="" />
            </div>
             {/* Modal component */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                <Modal.Title>Work in Progress</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>{message}</p>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                Close
                </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default AppDownload;