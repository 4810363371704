import axios from 'axios';

const placesAPIBaseUrl = process.env.REACT_APP_PLACES_API_URL;

const getHeadersForPlacesAPI = () => {
    return {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_PLACES_API_KEY
    };
};

export async function fetchAddressData(val) {

    try {
        console.log('input value -------------------------------> ' + val);

        const response = await axios.post(`${placesAPIBaseUrl}/getAddresses`, {
            input: val,
        }, {
            headers: getHeadersForPlacesAPI()
        });

        const data = response.data;
        return data.predictions;
    } catch (error) {
        console.error('Error fetching auto-complete results:', error);
    }
}



export async function getDistanceMatrixData(fromAddressId, toAddressId) {
    try {
        console.log('fromAddressId: ' + fromAddressId);
        console.log('toAddressId: ' + toAddressId);

        const response = await axios.post(`${placesAPIBaseUrl}/getDistance`, {
            fromPlaceId: fromAddressId,
            toPlaceId: toAddressId
        }, {
            headers: getHeadersForPlacesAPI()
        });

        const data = response.data;
        // Check if the API request was successful
        if (data.status === 'OK' && data.rows.length > 0 && data.rows[0].elements.length > 0) {
            const distanceText = data.rows[0].elements[0].distance.text;
            return data.rows[0].elements[0];
        } else {
            console.error('Error fetching distance:', data.error_message || data.status);
            return {};
        }
    } catch (error) {
        console.error('Error fetching distance:', error.message);
        return {};
    }
}