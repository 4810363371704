import React from 'react';

function ListFAQs() {
  const spanStyle = {
    fontWeight: 400
  };

  return (
    <div className="container">
      <div className="row">
        <aside className="col-xl-2 order-xl-3 sidebar sticky-sidebar mt-md-0 py-10 d-none d-xl-block">
          <div className="widget">
            <h6 className="widget-title fs-17 mb-2 ps-xl-5">On this page</h6>
            <nav className="ps-xl-5" id="sidebar-nav">
              <ul className="list-unstyled fs-sm lh-sm text-reset">
                <li><a className="nav-link scroll active" href="#snippet-1">Booking With Business Chauffeurs</a></li>
                <li><a className="nav-link scroll" href="#snippet-2">Travelling with luggage</a></li>
                <li><a className="nav-link scroll" href="#snippet-3">Travelling with children</a></li>
                <li><a className="nav-link scroll" href="#snippet-4">Changes, Cancellations & Surcharges</a></li>
                <li><a className="nav-link scroll" href="#snippet-5">Lost Property</a></li>
              </ul>
            </nav>
          </div>
        </aside>
        <div className="col-xl-10 order-xl-2">
          <section id="snippet-1" className="wrapper pt-10">
            <h2 className="mb-5">Booking With Business Chauffeurs</h2>
            <div className="accordion accordion-wrapper mt-10" id="accordion">
              <div className="card accordion-item">
                <div className="card-header" id="faq-0">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-0"
                    aria-expanded="true" aria-controls="faq-collapse-0"> How Do I Book With Business Chauffeurs? </button>
                </div>
                <div id="faq-collapse-0" className="accordion-collapse collapse" aria-labelledby="faq-0">
                  <div className="card-body">
                    <p>Book online here or download the Business Chauffeurs App on your Apple or Android device to make and manage
                      your booking on the go.</p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-1">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-1"
                    aria-expanded="true" aria-controls="faq-collapse-1"> How Do I Make Changes To My Booking? </button>
                </div>
                <div id="faq-collapse-1" className="accordion-collapse collapse" aria-labelledby="faq-1">
                  <div className="card-body">
                    <p>Manage your booking online here or on the go with the Business Chauffeurs App for Apple or Android devices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-3">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-3"
                    aria-expanded="true" aria-controls="faq-collapse-3"> Does Business Chauffeurs Charge Cancellation Fees? </button>
                </div>
                <div id="faq-collapse-3" className="accordion-collapse collapse" aria-labelledby="faq-3">
                  <div className="card-body">
                    <p>We only charge cancellation fees when bookings are cancelled within the notice period (including
                      passenger no-shows) because it means that the vehicle has been dedicated to you and can no longer
                      be assigned to another Business Chauffeurs passenger. The notice periods differ depending on location, vehicle
                      and event type. View our cancellation policy here.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <section id="snippet-2" className="wrapper pt-10">
            <h2 className="mb-5">Travelling With Luggage</h2>
            <div className="accordion accordion-wrapper mt-10" id="accordion">
              <div className="card accordion-item">
                <div className="card-header" id="faq-2">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-2"
                    aria-expanded="true" aria-controls="faq-collapse-2"> How Much Luggage Can I Bring In My Business Chauffeurs
                    Vehicle?</button>
                </div>
                <div id="faq-collapse-2" className="accordion-collapse collapse" aria-labelledby="faq-2">
                  <div className="card-body">
                    <p>Our sedan’s maximum luggage limitations are two (2) normal-sized suitcases (check-in size for a
                      plane) plus two (2) pieces of hand luggage/soft luggage (carry-on for a plane) or one (1) large
                      suitcase plus one (1) set of Golf Clubs and one (1) soft luggage. </p>

                    <p>Customers should consider booking a larger vehicle, such as a People Mover with a trailer, if
                      they have more luggage than a sedan can accommodate. No suitcases will be transported inside the
                      passenger area of the vehicle due to transport regulations and safety concerns.</p>

                    <p>If you have luggage beyond that outlined above or you are unsure of whether your luggage meets
                      requirements, we recommend talking to the Business Chauffeurs team on <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`}  className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a> to ensure you book the
                      appropriate vehicle for your needs.</p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-6">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-6"
                    aria-expanded="true" aria-controls="faq-collapse-6">Does A Charge Apply For My Luggage?</button>
                </div>
                <div id="faq-collapse-6" className="accordion-collapse collapse" aria-labelledby="faq-6">
                  <div className="card-body">
                    <p>If your luggage fits within the standard requirements for your booked vehicle type, there will
                      not be an additional charge. If you have excessive luggage and are travelling in a people mover,
                      minibus or coach, you can organise a trailer at the time of booking for a $30 charge. Please note
                      that trailers cannot be attached to sedans or SUVs in any state or people movers in Victoria.</p>
                    <p>Please ensure you have notified us accurately of your luggage requirements because a trailer
                      cannot be organised on pick up. If your luggage does not fit in the vehicle you have booked, we
                      cannot take responsibility for any excess, and you may be required to book a taxi to move the
                      remainder at your expense.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="snippet-3" className="wrapper pt-10">
            <h2 className="mb-3">Travelling With Children</h2>
            <div className="accordion accordion-wrapper mt-10" id="accordion">
              <div className="card accordion-item">
                <div className="card-header" id="faq-4">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-4"
                    aria-expanded="true" aria-controls="faq-collapse-4">Does Business Chauffeurs Offer Child Seats?</button>
                </div>
                <div id="faq-collapse-4" className="accordion-collapse collapse" aria-labelledby="faq-4">
                  <div className="card-body">
                    <p>Yes, Business Chauffeurs can provide clean, well-maintained child seats, suitable for newborns to 8 years old
                      in SUVs and people movers, provided we are notified at the time of booking. For safety and space
                      reasons, car seats are not available in sedans.</p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-5">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-5"
                    aria-expanded="true" aria-controls="faq-collapse-5"> What Age Requirements Apply?</button>
                </div>
                <div id="faq-collapse-5" className="accordion-collapse collapse" aria-labelledby="faq-5">
                  <div className="card-body">
                    <p>As Business Chauffeurs is a pre-booked service, there is an expectation under the law that there is sufficient
                      notice for a child restraint to be fitted. This means that all children aged under eight years
                      must travel in an age-appropriate restraint.</p>
                    <p>Whilst all care will be taken by chauffeurs to install child restraints safely,
                      parents/caregivers are strongly encouraged to check these prior to the start of the journey and
                      amend as and where required. Chauffeurs have training in installing child restraints. However,
                      they are not professional fitters, and the parent/caregiver of the child should be maintaining
                      their child’s safety in the same they would in their own vehicle.</p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-7">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-7"
                    aria-expanded="true" aria-controls="faq-collapse-7">Is There A Fee For Car Seats?</button>
                </div>
                <div id="faq-collapse-7" className="accordion-collapse collapse" aria-labelledby="faq-7">
                  <div className="card-body">
                    <p>A fee of $30 applies for bookings with car seats. This fee is per booking, not per seat.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="snippet-4" className="wrapper pt-10">
            <h2 className="mb-3">Changes, Cancellations And Surcharges</h2>
            <div className="accordion accordion-wrapper mt-10" id="accordion">
              <div className="card accordion-item">
                <div className="card-header" id="faq-8">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-8"
                    aria-expanded="true" aria-controls="faq-collapse-8">How Do I Make Changes To My Booking?</button>
                </div>
                <div id="faq-collapse-8" className="accordion-collapse collapse" aria-labelledby="faq-8">
                  <div className="card-body">
                    <p>Manage your booking online here or on the go with the Business Chauffeurs App for Apple or Android devices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-9">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-9"
                    aria-expanded="true" aria-controls="faq-collapse-9"> Does Business Chauffeurs Charge Cancellation Fees? </button>
                </div>
                <div id="faq-collapse-9" className="accordion-collapse collapse" aria-labelledby="faq-9">
                  <div className="card-body">
                    <p>We only charge cancellation fees apply when bookings are cancelled within the notice period
                      (including passenger no-shows) because it means that the vehicle has been dedicated to you and can
                      no longer be assigned to another Business Chauffeurs passenger. The notice periods differ depending on
                      location, vehicle and event type. View our cancellation policy here.</p>
                  </div>
                </div>
              </div>
              <div className="card accordion-item">
                <div className="card-header" id="faq-11">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-11"
                    aria-expanded="true" aria-controls="faq-collapse-11"> Does Business Chauffeurs Charge A Public Holiday
                    Surcharge?</button>
                </div>
                <div id="faq-collapse-11" className="accordion-collapse collapse" aria-labelledby="faq-11">
                  <div className="card-body">
                    <p>Use the code below to add link to dropdown parent:</p>
                  </div>
                  <div className="code-wrapper">
                    <div className="code-wrapper-inner">
                      <p>Depending on the public holiday and your location, surcharges may apply. Typically, surcharges
                        only apply in metropolitan areas on Christmas Day and New Year’s Day, but you can confirm by
                        contacting the Business Chauffeurs Customer Service team on <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`}  className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a> or res@Business Chauffeurs.com.au.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="snippet-5" className="wrapper py-10">
            <h2 className="mb-5">Lost Property</h2>
            <div className="accordion accordion-wrapper mt-10" id="accordion">
              <div className="card accordion-item">
                <div className="card-header" id="faq-12">
                  <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#faq-collapse-12"
                    aria-expanded="true" aria-controls="faq-collapse-12"> What Should I Do If I Think I Left An Item In
                    A Business Chauffeurs Vehicle? </button>
                </div>
                <div id="faq-collapse-12" className="accordion-collapse collapse" aria-labelledby="faq-12">
                  <div className="card-body">
                    <p>Please contact the Business Chauffeurs Customer Service Team as soon as possible on <a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`}  className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a> with details
                      of your booking so we can contact the driver. Lost property may be returned to you for a fee or
                      collected from a Customer Service Centre in the city or state of travel. While every attempt is
                      made to recover lost items, Business Chauffeurs, its Operators, and Licensees will not assume liability for any
                      lost or misplaced personal property.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ListFAQs;