import React, { useState, useEffect } from 'react';
import { getFleet } from '../../db/vehicleDAO';
import { Link } from 'react-router-dom';
import { loadExternalScripts, removeExternalScripts } from '../common/scripts';

function FleetRange() {

    const [fleetData, setFleetData] = useState([]);

    useEffect(() => {

        const getFleetData = async () => {
            try {
                const data = await getFleet();
                setFleetData(data);
                loadExternalScripts();
            } catch (error) {
                console.log('Error', error.message);
            } finally {
            }
        };

        getFleetData();

        return () => {
            removeExternalScripts(); // Cleanup scripts when the component is unmounted
        };

    }, []);

    const handleImageError = (event) => {
        event.target.src = '/assets/img/vehicles/cars.png';
    };
    
    return (

        <section className="wrapper bg-soft-gray pattern-wrapper bg-center">
            <div className="container pt-10 pt-md-10 pb-14 pb-md-14">

                <div className="row text-center mb-6">
                    <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
                        data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-dark mb-2">Range Of Vehicles </h2>
                        <h3 className="display-3 mb-2">A Range Of Vehicles To Keep Your Event Moving</h3>
                        <p className="fs-15 mb-4">Whether you are looking for a luxury limousine or premium sedan experience for VIPs or
                            want to move large groups of attendees in comfort and style, Chauffeurs has you covered, with vehicle
                            options for 1 – 61 passengers and an extensive fleet to accommodate large-scale events.</p>
                        <p className="lead fs-lg fw-bold">If we haven’t met your expectations, we would like to know.</p>
                    </div>
                </div>
                <div className="grid grid-view projects-masonry">
                    <div className="row gx-md-2 gy-2 isotope">
                        {fleetData.map((category) => (
                            <React.Fragment key={category.id}>
                                {category.vehicleTypes.map((vehicleType) => (
                                    <React.Fragment key={vehicleType.id}>
                                        <div key={vehicleType.id} className={`project item col-12 ${vehicleType.category.toLowerCase()}`} data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                            <div className="card mb-4 lift overflow-hidden">
                                                <div className="card-body p-0">
                                                    <div className="row justify-content-between align-items-end m-0">
                                                        <div className="col-md-4 col-12 p-3 pb-0">
                                                            <img src={`${vehicleType.logo?.path}/${vehicleType.logo?.name}`} className="img-flud" alt={"Vehicle"} onError={handleImageError} />
                                                            {/*<img src="/assets/img/photos/image 14.png" className="img-flud" />*/}
                                                        </div>
                                                        <div className="col-12 col-md-8 text-primary pe-md-0 px-0">
                                                            <h3 className="fs-25 pe-md-3 px-2 text-primary">{vehicleType.title}</h3>
                                                            <p className="mb-2 pe-md-3 px-2">{vehicleType.description}</p>
                                                            <div className="col-12 bg-primary p-0">
                                                                <span className="d-flex justify-content-between align-items-center p-3 p-md-2 px-2 gap-2 flex-md-nowrap flex-wrap">
                                                                    <span className="d-flex align-items-center text-white">
                                                                        <i className="uil uil-users-alt text-secondary me-1"></i> {vehicleType.features?.passenger?.max ?? 0} <span
                                                                            className="ms-2 d-none d-xl-inline">Passengers</span>
                                                                    </span>
                                                                    <span className="d-flex align-items-center text-white">
                                                                        <i className="uil uil-briefcase-alt text-secondary me-1"></i> {vehicleType.features?.suitcases ?? 0} <span
                                                                            className="ms-2 d-none d-xl-inline">Suitcases</span>
                                                                    </span>
                                                                    <span className="d-flex align-items-center text-white">
                                                                        <i className="uil uil-shopping-bag text-secondary me-1"></i> {vehicleType.features?.smallBags ?? 0} <span
                                                                            className="ms-2 d-none d-xl-inline">Small Bags</span>
                                                                    </span>
                                                                    <span className="text-white">
                                                                        <Link to="/booking" className="btn btn-sm btn-outline-secondary rounded">Book Now</Link>
                                                                    </span>
                                                                    <span className="text-white">
                                                                        <a href="#" className="btn btn-sm btn-outline-light rounded">Learn More</a>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </section>

    );
}

export default FleetRange;