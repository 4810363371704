import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import TermsAndConditions from './terms-and-conditions';
import HeroTermsAndConditions from './hero-terms-and-conditions';


function MainTermsAndConditions() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroTermsAndConditions />
            <TermsAndConditions />
            <Footer />
        </div>
    );
}

export default MainTermsAndConditions;