import { isNullOrUndefinedOrEmpty, isPickupTimeWithInOneHour, isValidEmail } from "../common/utilfn";


export function validateFeedbackFormData(formData, setValidationErrors) {

    let errorFlag = true;
    if (isNullOrUndefinedOrEmpty(formData.firstName)) {
        setErrors('firstName', 'Firstname is required.', setValidationErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(formData.lastName)) {
        setErrors('lastName', 'Lastname is required.', setValidationErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(formData.contact.email)) {
        setErrors('email', 'Email is required.', setValidationErrors);
        errorFlag = false;
    } else if (!isValidEmail(formData.contact.email)) {
        setErrors('email', 'Please enter the valid email address.', setValidationErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(formData.contact.phone)) {
        setErrors('phone', 'Phone number is required.', setValidationErrors);
        errorFlag = false;
    }

    if (isNullOrUndefinedOrEmpty(formData.subject)) {
        setErrors('subject', 'Subject is required.', setValidationErrors);
        errorFlag = false;
    }

    if (isNullOrUndefinedOrEmpty(formData.message)) {
        setErrors('message', 'Message is required.', setValidationErrors);
        errorFlag = false;
    }
    return errorFlag;
}

function setErrors(fieldName, errorMessage, setValidationErrors) {
    setValidationErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: errorMessage
    }));
}
