import React from 'react';

const SocialResponsibilityPolicy = () => {

    return (

        <div className="container">
            <p />
            <h1>Corporate Social Responsibility</h1>
            <p>
                Corporate Social Responsibility (CSR) involves managing our business processes for the benefit of society. At Business Class Chauffeur, we integrate CSR and sustainability principles as essential elements of our operations. We are committed to environmentally friendly practices across all aspects of our business, from office operations to our vehicles and cleaning products.
            </p>

            <h3>Our Commitments</h3>
            <ul>
                <li>Implementing ethical business practices.</li>
                <li>Working with integrity and respecting human rights.</li>
                <li>Promoting equal opportunity, equality, and diversity.</li>
                <li>Acting in a socially and environmentally responsible way.</li>
                <li>Considering the interests of all stakeholders, including investors, suppliers, customers, employees, and the community.</li>
                <li>Respecting the rule of law and adhering to regulations.</li>
                <li>Minimizing any negative impact on the environment.</li>
                <li>Supporting philanthropic initiatives and volunteer efforts.</li>
            </ul>
        </div>
    );
};


export default SocialResponsibilityPolicy;