import { db } from '../config/firebase-config';
import { collection, getDocs, getDoc, doc, query } from 'firebase/firestore';

const EMPTY_COLLECTION = [];
const EMPTY_OBJECT = {};

export async function getDocuments(collectionPath) {
    try {
        const subCollectionCRef = collection(db, collectionPath);
        return await processDocuments(subCollectionCRef);
    } catch (error) {
        console.error('getDocuments: Exception occured while fetching the data ', error);
    }
    return EMPTY_COLLECTION;
}

export async function getDocumentById(collectionPath, documentId) {
    try {
        const documentRef = doc(db, collectionPath, documentId.toString());
        const document = await getDoc(documentRef);
        return document.data();
    } catch (error) {
        console.error('getDocumentById: Exception occured while fetching the data ', error);
    }
    return EMPTY_OBJECT;
}

export async function getDocumentsByCriteria(collectionPath, conditions = []) {
    try {
        const subCollectionCRef = collection(db, collectionPath);
        const filteredQuery = query(subCollectionCRef, ...conditions);
        return await processDocuments(filteredQuery);
    } catch (error) {
        console.error('getDocumentsByCriteria: Exception occured while fetching the data ', error);
    }
    return EMPTY_COLLECTION;
}

async function processDocuments(fetchDocumentsCriteria) {
    const querySnapshot = await getDocs(fetchDocumentsCriteria);
    const docs = [];
    querySnapshot.docs.forEach((doc) => {
        docs.push(doc.data());
    });
    return docs;
}