import React, { useState, useEffect } from 'react';
import { signIn, passwordReset } from '../../services/auth-service';
import { Link, useNavigate } from 'react-router-dom';
import { isNullOrUndefinedOrEmpty, isValidEmail } from '../common/utilfn';

function Signin({ resetFlag }) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const [passwordResetFlag, setPasswordResetFlag] = useState(resetFlag);
  const [passwordResetMessage, setPasswordResetMessage] = useState('');
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (authenticatedUser) {
      navigate('/');
    }
  }, [authenticatedUser]);

  useEffect(() => {
    setPasswordResetFlag(resetFlag);
    setErrors({});
  }, [resetFlag]);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    try {
      const flag = await passwordReset(email);
      console.log("User signed up:", flag);
      setPasswordResetMessage('Please check your email for the password reset link.');
      setPasswordResetFlag(false);
    } catch (error) {
      console.error("Sign-up error:", error.message);
    }
  };


  const handleSignIn = async (event) => {
    event.preventDefault();
    if (!validateSignInForm()) {
      return;
    }
    try {
      const user = await signIn(email, password);
      console.log("User signed up:", user);
      setAuthenticatedUser(true);
    } catch (error) {
      if (error.code === 'auth/invalid-credential') {
        let validationError = {};
        validationError.invalidCredentials = (
          <div>
            <p style={{ color: 'red', fontWeight: 'bold' }}>
              Invalid credentials, Please try to login again!
            </p>
          </div>
        );
        setErrors(validationError);
      }
      console.error("Sign-up error:", error.message);
    }
  };

  const validateSignInForm = () => {
    setErrors({});
    let validationErrors = {};

    let flag = true;

    if (isNullOrUndefinedOrEmpty(email)) {
      validationErrors.email = 'Email is required.';
      flag = false;
    } else if (!isValidEmail(email)) {
      flag = false;
      validationErrors.email = 'Please enter the valid email address.';
    }
    if (isNullOrUndefinedOrEmpty(password)) {
      validationErrors.password = 'Password is required.';
      flag = false;
    }
    if (!flag) {
      setErrors(validationErrors);
    }
    return flag;
  }

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div>
      <section className="wrapper bg-light">
        <div className="container py-10">
          <div className="row text-center">
            <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
              data-delay={process.env.REACT_APP_DELAY}>
              <h2 className="fs-16 text-uppercase text-dark mb-2">Sign in to Chauffeurs </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-6 col-xxl-6 mx-auto" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
              <div className="p-11 text-center">
                <form className="text-start mb-3">
                  <div className="form-floating mb-4">
                    <input type="email"
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      placeholder="Email"
                      id="loginEmail"
                      onChange={(e) => setEmail(e.target.value)} />
                    <label htmlFor="loginEmail">Email <span className="text-danger">*</span></label>
                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                  </div>
                  {!passwordResetFlag &&
                    (
                      <>

                        <div className="form-floating password-field mb-4 position-relative">
                          <input
                            type={isPasswordVisible ? "text" : "password"}
                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="Password"
                            id="loginPassword"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <label htmlFor="loginPassword">Password <span className="text-danger">*</span></label>
                          {errors.password && <div className="invalid-feedback">{errors.password}</div>}

                          <span
                            className="password-toggle position-absolute end-0 top-50 translate-middle-y me-2 cursor-pointer"
                            onClick={togglePasswordVisibility}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className={`uil ${isPasswordVisible ? 'uil-eye-slash' : 'uil-eye'}`}></i>
                          </span>
                        </div>
                        <input type="button" className="btn btn-secondary rounded btn-login w-100 mb-2"
                          onClick={handleSignIn}
                          value="Sign In" />
                      </>

                    )}
                  {errors.invalidCredentials && (
                    <div className="error-message">
                      {errors.invalidCredentials}
                    </div>
                  )}
                  {passwordResetFlag && (
                    <input type="button" className="btn btn-secondary rounded btn-login w-100 mb-2"
                      onClick={handlePasswordReset}
                      value="Reset" />

                  )}
                  {passwordResetMessage && (
                    <div class="text-danger">{passwordResetMessage}</div>
                  )}
                </form>
                <p className="mb-1">
                  <Link to="/signin?resetFlag=true" className="hover">Forgot Password?
                  </Link>
                </p>

                <p className="mb-0">Don't have an account?
                  <Link to="/signup" className="hover">Sign up</Link>
                </p>
                {/*
                <div className="divider-icon my-4">or</div>
                <nav className="nav social justify-content-center text-center">
                  <a href="https://www.facebook.com/share/4jdT2iFULoZ8cqw9/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="btn btn-circle btn-sm btn-facebook-f"><i className="uil uil-facebook-f"></i></a>
                  <a href="https://www.instagram.com/businessclasschauffeur/profilecard/?igsh=MWt1Nzc4djF1NGp1Yg==" target="_blank" rel="noopener noreferrer"><i className="uil uil-instagram"></i></a>
                </nav>
                */}
              </div>
              
            </div>
          </div>

        </div>
      </section>
    </div>
  );
}

export default Signin;