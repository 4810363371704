import React from 'react';

function JoinFleet() {

    const topStyle = {
        top: "15%; left: -7%"
    };

    const bottomStyle = {
        bottom: "10%; left: -10%"
    };
    

    return (
        <section className="wrapper bg-light">
            <div className="container py-10 py-md-10">
                <div className="row mb-3">
                    <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center" data-cues="slideInDown"
                        data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-dark mb-2">The Fleet</h2>
                        <h3 className="display-3 mb-2">Join The Fleet – Business Chauffeur</h3>
                        <p className="fs-15">Business Chauffeur prides itself on hiring Australia’s most professional and reliable chauffeurs.</p>
                    </div>
                </div>
                <div className="container py-6 py-md-6">
                    <div className="row gx-md-8 gy-10 align-items-center justify-content-between">
                        <div className="col-md-5 col-12" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                            <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">What Makes Us Different?</h2>
                            <h3 className="display-4 mb-4 me-lg-n5">We make spending stress free so you have the perfect control.</h3>
                            <p className="mb-6">Etiam porta sem malesuada magna mollis euismod. Donec ullamcorper nulla non metus auctor
                                fringilla. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Fusce dapibus, tellus ac
                                cursus. Integer posuere erat a ante venenatis dapibus posuere velit.</p>
                            <ul className="icon-list bullet-bg bullet-soft-yellow">
                                <li><i className="uil uil-check"></i>Aenean eu leo quam. Pellentesque ornare.</li>
                                <li><i className="uil uil-check"></i>Nullam quis risus eget urna mollis ornare.</li>
                                <li><i className="uil uil-check"></i>Donec id elit non mi porta gravida at eget.</li>
                            </ul>
                            <div className="d-flex justify-content-center justify-content-lg-start mb-5">
                                <span><a className="btn btn-sm btn-secondary rounded me-2">JOIN AS A CHAUFFEUR</a></span>
                                <span><a className="btn btn-sm btn-primary rounded me-2">JOIN AS A CONTRACTOR</a></span>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 position-relative" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                            <figure className="rounded"><img className="img-fluid" src="./assets/img/photos/img4.jpg"
                                srcset="./assets/img/photos/about27@2x.jpg 2x" alt="" /></figure>
                            <div className="card shadow-lg position-absolute d-none d-md-block" style={topStyle}>
                                <div className="card-body py-4 px-5">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <img src="./assets/img/icons/solid/cloud-group.svg"
                                                className="svg-inject icon-svg icon-svg-sm solid-duo text-grape-fuchsia me-3" alt="" />
                                        </div>
                                        <div>
                                            <h3 className="fs-25 counter mb-0 text-nowrap">25000+</h3>
                                            <p className="fs-16 lh-sm mb-0 text-nowrap">Happy Clients</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow-lg position-absolute text-center d-none d-md-block"
                                style={bottomStyle}>
                                <div className="card-body p-6">
                                    <div className="progressbar semi-circle yellow mb-3" data-value="80"></div>
                                    <h4 className="mb-0">Time Saved</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JoinFleet;