import React from 'react';
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {

  const policies = [
    { name: 'Cancellation Policy', path: '/cancellationPolicy' },
    { name: 'Child Safety Policy', path: '/childSafetyPolicy' },
    { name: 'Data Retention & Privacy Policy', path: '/dataRetentionAndPrivacyPolicy' },
    { name: 'Fatigue Management Policy', path: '/fatigueManagementPolicy' },
    { name: 'Social Responsibility Policy', path: '/socialResponsibilityPolicy' }
  ];

  

  return (
    <div className="container">
      <p />
      <h1>Business Class Chauffeur - Company policies</h1>
      <p>
        In our pursuit of excellence, we have established a thorough set of company policies that guide our operations. These policies cover essential areas including vehicle upkeep, safety measures, driver behavior, reservation processes, and customer support. By following these guidelines, we strive to ensure a smooth and enjoyable experience for all our clients, while maintaining the highest levels of professionalism and reliability in our industry.
      </p>
      <p></p>
      <div className="row">
        <ul>
          {policies.map((policy, index) => (
            <li key={index}>
              <Link to={policy.path}>{policy.name}</Link>
              <p></p>
            </li>
          ))}
        </ul>
      </div>
    </div>

  );
};

export default PrivacyPolicy;
