import React from 'react';
import { Link } from 'react-router-dom'

function HeroChildSafetyPolicy() {
  return (
    <section className="wrapper image-wrapper bg-image bg-overlay text-white" data-image-src="./assets/img/photos/bg1.jpg">
      <div className="container py-12 position-relative">
        <div className="row gx-0 align-items-center">
          <div className="col-12 col-md-6 text-start" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="py-1 py-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active" ><Link to="/bccPolicies">Policies</Link></li>
                  <li className="breadcrumb-item active text-muted" aria-current="page">Child Safety Policy</li>
                </ol>
              </nav>
            </div>
            <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0 text-white">Child Safety Policy</h1>
            <p className="fs-15 mb-4">Business Class Chauffeur - Child Safety Policy</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroChildSafetyPolicy;