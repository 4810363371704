import React from 'react';
import FleetRange from './fleet-range';


function BookingVehicleSelection() {

    return (
        <fieldset className="step" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="container">
                <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-4 mt-1">
                    <div className="col-md-6">
                        <div className="location-header">
                            <i className="uil uil-taxi"></i> <span>Vehicle Selection</span>
                        </div>
                    </div>
                </div>
                <FleetRange />
            </div>
        </fieldset>
    );
}

export default BookingVehicleSelection;