import React from 'react';

const FatigueManagementPolicy = () => {

    return (

        <div className="container">
            <p />
            <h1>Fatigue Management Policy</h1>
            <p>
                Business Class Chauffeur has established this Fatigue Management Policy to enhance the safety of all clients, staff, and contractors. This policy aligns with OH&S guidelines and must be strictly adhered to at all times.
            </p>

            <h3>Compliance</h3>
            <p>
                All staff, contractors, chauffeurs, and drivers are required to follow the Fatigue Management Guidelines. Managers and supervisors must be trained in the Corporate Fatigue Management Guidelines and understand factors contributing to fatigue.
            </p>

            <h3>Responsibilities</h3>
            <h4>Management & Supervisors</h4>
            <p>
                Management and supervisors must provide adequate notice to contractors, chauffeurs, and drivers who may need to work up to 14 hours in a single day. If consecutive days of 14-hour shifts are required, these must be monitored, ensuring sufficient breaks are provided during the work period.
            </p>

            <h4>All Staff</h4>
            <p>
                Contractors, chauffeurs, and drivers working more than 6 consecutive days should not work longer than 5 hours without a reasonable break each day, particularly on the 7th day. Sufficient breaks must be given between shifts, and no one should be scheduled for seven consecutive days.
            </p>

            <h4>Shift Breaks</h4>
            <p>
                ETG contractors, chauffeurs, and drivers must have a minimum of 10 hours break between shifts, allowing for at least 6-8 hours of uninterrupted sleep whenever possible. This sleep period should ideally occur between 9 PM and 8 AM, depending on shift schedules.
            </p>

            <h4>Rural Trips</h4>
            <p>
                Ample notice must be given to contractors, chauffeurs, and drivers required for trips to rural areas. A managed trip roster must be implemented and followed strictly. Drivers should rest for at least 12 hours before undertaking long trips that exceed 14 hours away from the base.
            </p>

            <h4>Fatigue Reporting</h4>
            <p>
                Contractors, chauffeurs, and drivers are responsible for monitoring their own safety and hours worked, both on and off shift. They must inform management if they feel fatigued while on duty, regardless of the hours already worked. Off-shift, they are also responsible for adhering to company guidelines regarding breaks and sleep patterns.
            </p>

            <h4>Health Assessments</h4>
            <p>
                Regular health assessments must be conducted in line with the Fatigue Management Policy and the Operations Standards Manual. Contractors, chauffeurs, and drivers should undergo a health evaluation when renewing their Driving Authority, with a medical report submitted to the Department of Transport (if applicable) and to Business Class Chauffeur's Operations Manager or supervisors within 14 days of the assessment.
            </p>

            <h4>Management Review</h4>
            <p>
                Management is responsible for reviewing each medical assessment report within 7 days of receipt.
            </p>

            <h4>Substance Use</h4>
            <p>
                Contractors, chauffeurs, and drivers must ensure their alcohol and drug consumption complies with state laws and does not impair their ability to perform their duties during rostered shifts. There is a zero-tolerance policy for alcohol and drug use while operating a limousine or coach.
            </p>

            <h4>Leave and Wellbeing</h4>
            <p>
                Contractors, chauffeurs, and drivers should take adequate leave each year to ensure their wellbeing.
            </p>

            <h3>Conclusion</h3>
            <p>
                Each individual plays a vital role in the successful implementation of the Fatigue Management Policy. Business Class Chauffeur is committed to providing assistance to foster a safe, clean, and positive working environment. All stakeholders must take ownership of the Fatigue Management Guidelines. Strict disciplinary measures will be enforced against anyone who fails to comply with this policy and its guidelines.
            </p>
        </div>
    );
};


export default FatigueManagementPolicy;