import { db } from '../config/firebase-config';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import * as FIREBASE_PATHS from '../config/firebase-path-mapping';
import * as genericDAO from './genericDAO';

export async function saveFeedbackMessage(data) {

    try {
        const now = Timestamp.fromDate(new Date());
        data.creationDate = now;

        const feedbackCollection = collection(db, FIREBASE_PATHS.FEEDBACK_COLLECTION_PATH);
        let response = await addDoc(feedbackCollection, data);
        console.log('Feedback submitted successfully ' + response.id);
        return response.id;
    } catch (error) {
        console.error('Error while submittine the feedback', error);
        return -1;
    }
};

export async function getFeedbackMessages() {
    return await genericDAO.getDocuments(FIREBASE_PATHS.FEEDBACK_COLLECTION_PATH);
}