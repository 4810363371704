import React from 'react';
import { Link } from 'react-router-dom';

function SectionFeatures() {
    return (
        <section className="wrapper gradient-8">
            <div className="container pt-10 pt-md-10 pb-14 pb-md-14">
                <div className="row text-center mb-6">
                    <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
                        data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-dark mb-2">Comfort And Style</h2>
                        <h3 className="display-3 mb-2">Moving You In Comfort And Style Wherever You Need To Go</h3>
                        <p className="fs-15">When you select Chauffeurs, you opt for luxury, style, comfort, and, above all, experience.
                            We've mastered the art of creating memorable journeys. From our friendly drivers to our well-appointed vehicles and thoughtful amenities like umbrellas, coffees, and
                            local expertise, you can trust that you're in capable hands with Chauffeurs.</p>
                    </div>
                </div>
                <div className="swiper-container nav-bottom nav-color mb-6" data-margin="20" data-dots="false" data-nav="true"
                    data-items-lg="4" data-items-md="2" data-items-xs="1">
                    <div className="swiper pb-2">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <article>
                                    <div className="card shadow-lg">
                                        <figure className="card-img-top overlay overlay-1"><a href="#"> <img src="./assets/img/photos/b12.jpg"
                                            alt="" /></a>
                                            {/*
                                            <figcaption>
                                                <h5 className="from-top mb-0">Read More</h5>
                                            </figcaption>
                                            */}
                                        </figure>
                                        <div className="card-body p-3">
                                            <div className="post-header">
                                                <h3 className="post-title h4 mt-1 mb-3">
                                                    <Link to="/booking" className="link-secondary">Corporate Transfer</Link></h3>
                                                <p className="mb-0">Top choice for Aussie & global corps. Easy booking, payments. Premium service
                                                    ensures hassle-free journeys.</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div className="swiper-slide" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <article>
                                    <div className="card shadow-lg">
                                        <figure className="card-img-top overlay overlay-1"><a href="#"> <img src="./assets/img/photos/b13.jpg"
                                            alt="" /></a>
                                            {/*
                                            <figcaption>
                                                <h5 className="from-top mb-0">Read More</h5>
                                            </figcaption>
                                            */}
                                        </figure>
                                        <div className="card-body p-3">
                                            <div className="post-header">
                                            <h3 className="post-title h4 mt-1 mb-3">
                                            <Link to="/booking" className="link-secondary">Airport Transfer</Link></h3>
                                                <p className="mb-0">Reliable, timely airport transfers for stress-free travel. Professional drivers and premium vehicles ensure you arrive or depart in comfort.</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div className="swiper-slide" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <article>
                                    <div className="card shadow-lg">
                                        <figure className="card-img-top overlay overlay-1"><a href="#"> <img src="./assets/img/photos/b14.jpg"
                                            alt="" /></a>
                                            {/*
                                            <figcaption>
                                                <h5 className="from-top mb-0">Read More</h5>
                                            </figcaption>
                                            */}
                                        </figure>
                                        <div className="card-body p-3">
                                            <div className="post-header">
                                            <h3 className="post-title h4 mt-1 mb-3">
                                            <Link to="/booking" className="link-secondary">Luxury Tours</Link></h3>
                                                <p className="mb-0">Experience Australia's stunning sights in style. Our luxury tours offer personalized service, premium vehicles, and unforgettable memories.</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <div className="swiper-slide" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <article>
                                    <div className="card shadow-lg">
                                        <figure className="card-img-top overlay overlay-1"><a href="#"> <img src="./assets/img/photos/b15.jpg"
                                            alt="" /></a>
                                            {/*
                                            <figcaption>
                                                <h5 className="from-top mb-0">Read More</h5>
                                            </figcaption>
                                            */}
                                        </figure>
                                        <div className="card-body p-3">
                                            <div className="post-header">
                                            <h3 className="post-title h4 mt-1 mb-3">
                                            <Link to="/booking" className="link-secondary">Cruise Ship Transfers</Link></h3>
                                                <p className="mb-0">Seamless transfers to and from cruise terminals. Enjoy a smooth ride with professional chauffeurs, ensuring a stress-free start or end to your journey.</p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SectionFeatures;