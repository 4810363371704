import React from 'react';

const ChildSafetyPolicy = () => {


    const childSafetyPolicies = [
        "Ensuring the safety of unaccompanied minors arriving in Australia who need transport to their homestay accommodation.",
        "Making certain that minors are greeted by a designated adult when being taken to their homestay.",
        "Guaranteeing that only chauffeurs with a valid Working with Children Check interact with or transport minors.",
        "Protecting minors from culturally and/or linguistically diverse backgrounds."
    ]

    return (

        <div className="container">
            <p />
            <h1>Child Safety Policy</h1>
            <p>Business Class Chauffeur is dedicated to being a child-safe and child-friendly organization. We acknowledge our responsibility to protect children from abuse by anyone associated with our services, making their safety our top priority. We adopt a zero-tolerance policy towards child abuse and are committed to valuing diversity, with no tolerance for discriminatory practices.</p>
            <p>Our specific focus includes:</p>
            <ul>
                {childSafetyPolicies.map((policy, index) => (
                    <li key={index}>{policy}</li>
                ))}
            </ul>
        </div>
    );
};


export default ChildSafetyPolicy;