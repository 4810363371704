import { db } from '../config/firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import * as FIREBASE_PATHS from '../config/firebase-path-mapping';
import { getDocumentById } from './genericDAO';

export async function createUserProfile(user) {

    try {
        // Create a user profile in Firestore
        await setDoc(doc(db, "users", user.uid), {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            createdAt: new Date()
        });

        console.log("User profile saved successfully:", user);
    } catch (error) {
        console.error("Error while creating user profile:", error.message);
    }
};

export async function getUserById(uid) {
    return await getDocumentById(FIREBASE_PATHS.USERS_COLLECTION_PATH, uid.toString())
}
