import React, { useContext } from 'react';
import { BookingContext } from '../../../contexts/bookingContext';
import GoogleMapIframe from '../../common/GoogleMapIframe';
import { convertTo12HourFormat } from '../../common/utilfn';

function BookingSummary() {
    let { bookingDetails, setBookingDetails } = useContext(BookingContext);

    return (
        <fieldset className="step" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="container">
                <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">
                    <div className="col-12">
                        <div className="form-heading">
                            <h3>Summary Details</h3>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <GoogleMapIframe fromPlaceId={bookingDetails.location.fromPlaceId} toPlaceId={bookingDetails.location.toPlaceId} />
                        {/*   <div className="map map-full rounded-top rounded-lg-start">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25387.23478654725!2d-122.06115399490332!3d37.309248660190086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb4571bd377ab%3A0x394d3fe1a3e178b4!2sCupertino%2C%20CA%2C%20USA!5e0!3m2!1sen!2str!4v1645437305701!5m2!1sen!2str"
                                        style={textareaStyle} allowFullScreen></iframe>
                                </div>
                            */}
                    </div>
                    <div className="col-12 mb-4">
                        <div className="d-flex flex-row">
                            <div>
                                <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                            </div>

                            <div className="w-100 align-self-start justify-content-start">
                                <h5 className="mb-1 text-secondary">Address</h5>
                                {(() => {
                                    // List of stop locations and their flags
                                    const stops = [
                                        { location: bookingDetails.location.stopLocation, flag: bookingDetails.location.stopLocationFlag, label: 'Stop 1' },
                                        { location: bookingDetails.location.stopLocation2, flag: bookingDetails.location.stopLocationFlag2, label: 'Stop 2' },
                                        { location: bookingDetails.location.stopLocation3, flag: bookingDetails.location.stopLocationFlag3, label: 'Stop 3' },
                                        { location: bookingDetails.location.stopLocation4, flag: bookingDetails.location.stopLocationFlag4, label: 'Stop 4' }
                                    ];

                                    // Filter the stops that are active (i.e., flag is true)
                                    const activeStops = stops.filter(stop => stop.flag);

                                    return (
                                        <div className="address-list">
                                            {/* From location */}
                                            <div className="d-flex justify-content-between">
                                                <address>Pickup: <span className="text-muted">{bookingDetails.location.pickupLocation}</span></address>
                                            </div>

                                            {/* Show each stop in its own row */}
                                            {activeStops.map((stop, idx) => (
                                                <div className="d-flex justify-content-between" key={idx}>
                                                    <address>{stop.label}: <span className="text-muted">{stop.location}</span></address>
                                                </div>
                                            ))}

                                            {/* To location */}
                                            <div className="d-flex justify-content-between">
                                                <address>Drop: <span className="text-muted">{bookingDetails.location.dropLocation}</span></address>
                                            </div>
                                        </div>
                                    );
                                })()}
                            </div>

                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="d-flex flex-row">
                            <div>
                                <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-calendar-alt"></i> </div>
                            </div>
                            <div className="align-self-start justify-content-start">
                                <h5 className="mb-1 text-secondary">Date & Time</h5>
                                <div className="d-block">Booking Date : <span className="text-muted">{bookingDetails.generalDetails.bookingDate}</span></div>
                                <div className="d-block">Pickup Time : <span className="text-muted">{convertTo12HourFormat(bookingDetails.generalDetails.bookingTime, true)}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="d-flex flex-row">
                            <div>
                                <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-clock"></i> </div>
                            </div>
                            <div className="align-self-start justify-content-start">
                                <h5 className="mb-1 text-secondary">Estimated Time</h5>
                                <div className="d-block"></div>
                                <div className="d-block">Travel Time : <span className="text-muted"> {bookingDetails.distanceMatrix.duration.text} </span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="d-flex flex-row">
                            <div>
                                <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-taxi"></i> </div>
                            </div>
                            <div className="align-self-start justify-content-start">
                                <h5 className="mb-1 text-secondary">Vehicle Details</h5>
                                <div className="d-block">Vehicle : <span className="text-muted"> {bookingDetails.vehicleSelection.name} </span></div>
                                <div className="d-block">Passengers : <span className="text-muted">{bookingDetails.generalDetails.passengersCount}</span></div>
                                {bookingDetails.generalDetails.byTheHourCheck && (
                                    <div className="d-block">Booked for : <span className="text-muted">{bookingDetails.generalDetails.numberOfHours} hours</span></div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    );
}

export default BookingSummary;