import {React, useEffect} from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import DistinguishedFleet from '../general/distinguished-fleet';
import FleetRange from '../general/fleet-range';
import HeroFleet from './hero-about';
import HeroAbout from './hero-about';
import GroundTransport from '../general/ground-transport';
import WhyChooseUs from '../general/why-choose-us';


function MainAbout() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroAbout />
      <GroundTransport />
      <WhyChooseUs />
      <Footer />
    </div>
  );
}

export default MainAbout;