import React from 'react';
import HeroSocialResponsibilityPolicy from './hero-social-responsibility-policy';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import SocialResponsibilityPolicy from './social-responsibility-policy';


function MainSocialResponsibilityPolicy() { 
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroSocialResponsibilityPolicy />
            <SocialResponsibilityPolicy />
            <Footer />
        </div>
    );
}

export default MainSocialResponsibilityPolicy;