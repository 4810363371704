import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroJoinFleet from './hero-join-fleet';
import JoinFleet from './join-fleet';


function MainJoinFleet() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroJoinFleet />
      <JoinFleet />
      <Footer />
      
    </div>
  );
}

export default MainJoinFleet;