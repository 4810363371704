const paymentAPIBaseUrl = process.env.REACT_APP_PAYMENT_API_URL;
const paymentAPIKey = process.env.REACT_APP_PAYMENT_API_KEY;
const getHeadersForPaymentAPI = () => {
    return {
        "Content-Type": "application/json",
        "x-api-key": paymentAPIKey
    };
};

export async function updatePaymentIntentWithBookingId(paymentIntentId, bookingId) {
    const res = await fetch(`${paymentAPIBaseUrl}/updatePaymentIntent`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentIntentId,
            bookingId: bookingId,
        }),
    });

    var { updatedPaymentIntent } = await res.json();
    return updatedPaymentIntent;
}

export async function createPaymentIntent(bookingFee) {
    const res = await fetch(`${paymentAPIBaseUrl}/createPaymentIntent`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            currencyType: 'AUD',
            bookingAmount: bookingFee,
        }),
    });
    const response = await res.json();
    return response;
}

export async function getPaymentConfiguration() {
    const res = await fetch(`${paymentAPIBaseUrl}/getPublishableKey`, {
        method: "GET", // explicitly set the method to GET
        headers: getHeadersForPaymentAPI() // use the common headers which include the x-api-key
    });

    const { publishableKey } = await res.json();
    return publishableKey;
}

export async function refundPaymentWithFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/refundFullPayment`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}

export async function refundPaymentWithoutFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/refundPartialPayment`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}

export async function getBookingFees(paymentId) {
    const res = await fetch(`${paymentAPIBaseUrl}/getTransactionFee`, {
        method: "POST",
        headers: getHeadersForPaymentAPI(),
        body: JSON.stringify({
            paymentIntentId: paymentId,
        }),
    });
    const response = await res.json();
    return response;
}