import React from 'react';

const TermsAndConditions = () => {
  return (
      <div className="container">
      <p/>
      <h1>General Terms of Carriage</h1>
      
      <p>
        Business Class Chauffeur (BCC) retain the right to refuse service to any individual without providing a reason.
      </p>
      <p>
        It is the responsibility of the hirer to thoroughly review the FULL TERMS & CONDITIONS, as confirming a booking indicates acceptance of these terms.
      </p>
      <p>
        BCC will not be held liable for any costs or losses, including missed flights or connections, resulting from diversions, substitutions, changes, cancellations, delays, or booking errors. It is the hirer's duty to communicate any changes, as full costs may apply.
      </p>
      <p>
        Passengers are required to comply with all applicable laws and regulations in the jurisdiction of travel, as well as the directives issued by BCC.
      </p>
      <p>
        Except as specified in these terms and to the extent permitted by law: (a) BCC is not liable for any injuries, deaths, loss or damage to luggage or personal belongings, delays, or any consequential loss arising from negligence or any other cause in connection with the provision of services; (b) they are also not responsible for inaccuracies related to transport services or pricing.
      </p>
      <div className="row">

      <div className="col-xl-10 order-xl-2">
      <section id="snippet-1" className="wrapper pt-0">
      <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
            <div className="card-header" id="amend-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#amend-collapse-0"
                aria-expanded="true" aria-controls="amend-collapse-0"> Amendments </button>
            </div>
            <div id="amend-collapse-0" className="accordion-collapse collapse" aria-labelledby="amend-0">
              <div className="card-body">
                <p>Changes to confirmed bookings can only be accepted based on availability. BCC reserves the right to impose an amendment fee alongside any other applicable charges.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="consumption-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#consumption-collapse-0"
                aria-expanded="true" aria-controls="consumption-collapse-0"> Consumption in Vehicle </button>
            </div>
            <div id="consumption-collapse-0" className="accordion-collapse collapse" aria-labelledby="consumption-0">
              <div className="card-body">
                <p>Food and beverages, except for bottled water, are not permitted in the vehicle.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="credit-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#credit-collapse-0"
                aria-expanded="true" aria-controls="credit-collapse-0"> Credit Card Charges </button>
            </div>
            <div id="credit-collapse-0" className="accordion-collapse collapse" aria-labelledby="credit-0">
              <div className="card-body">
                <p>A surcharge may be applied to credit card transactions at the discretion of the local Customer Service Centre where the service is rendered.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="safety-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#safety-collapse-0"
                aria-expanded="true" aria-controls="safety-collapse-0"> Customer Safety </button>
            </div>
            <div id="safety-collapse-0" className="accordion-collapse collapse" aria-labelledby="safety-0">
              <div className="card-body">
                <p>Our chauffeurs will always operate at safe speeds and in accordance with traffic laws and current road conditions. They reserve the right to refuse access to locations deemed unsafe for passengers or the vehicle.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="damage-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#damage-collapse-0"
                aria-expanded="true" aria-controls="damage-collapse-0"> Damage & Behavior </button>
            </div>
            <div id="damage-collapse-0" className="accordion-collapse collapse" aria-labelledby="damage-0">
              <div className="card-body">
                <p>Chauffeurs will maintain safe driving practices. Access may be denied to any venue that poses risks to the passengers or vehicle.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="extensions-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#extensions-collapse-0"
                aria-expanded="true" aria-controls="extensions-collapse-0"> Extensions </button>
            </div>
            <div id="extensions-collapse-0" className="accordion-collapse collapse" aria-labelledby="extensions-0">
              <div className="card-body">
                <p>The hirer is responsible for any additional charges incurred due to changes in the original booking. This includes extensions of time or additional stops.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="charges-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#charges-collapse-0"
                aria-expanded="true" aria-controls="charges-collapse-0"> Extra Charges </button>
            </div>
            <div id="charges-collapse-0" className="accordion-collapse collapse" aria-labelledby="charges-0">
              <div className="card-body">
                <p>Extra charges must be settled immediately with the chauffeur upon completion of the transfer. These can also be charged to the hirer's BCC account or credit card used for booking.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="insurance-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#insurance-collapse-0"
                aria-expanded="true" aria-controls="insurance-collapse-0"> Insurance </button>
            </div>
            <div id="insurance-collapse-0" className="accordion-collapse collapse" aria-labelledby="insurance-0">
              <div className="card-body">
                <p>BCC, its Operators, and Licensees carry public liability insurance for passenger travel. However, this does not cover personal property. Ensure you have adequate insurance for any items brought onto our vehicles.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="lost-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#lost-collapse-0"
                aria-expanded="true" aria-controls="lost-collapse-0"> Lost & Found </button>
            </div>
            <div id="lost-collapse-0" className="accordion-collapse collapse" aria-labelledby="lost-0">
              <div className="card-body">
                <p>BCC is not responsible for lost or misplaced items. Any items left in the vehicle will be returned at the customer’s expense or can be collected from our Customer Service Centre.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="luggage-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#luggage-collapse-0"
                aria-expanded="true" aria-controls="luggage-collapse-0"> Luggage Guidelines </button>
            </div>
            <div id="luggage-collapse-0" className="accordion-collapse collapse" aria-labelledby="luggage-0">
              <div className="card-body">
                <p>Our sedan can accommodate up to two standard-sized suitcases plus two pieces of hand luggage or one large suitcase with one set of golf clubs and one soft bag. For excess luggage, consider booking a larger vehicle, as extra charges will apply.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="pickups-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#pickups-collapse-0"
                aria-expanded="true" aria-controls="pickups-collapse-0"> Multiple Pickups/Set Downs </button>
            </div>
            <div id="pickups-collapse-0" className="accordion-collapse collapse" aria-labelledby="pickups-0">
              <div className="card-body">
                <p>Additional charges will be applied for extra pickups or set downs unless previously quoted.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="timeliness-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#timeliness-collapse-0"
                aria-expanded="true" aria-controls="timeliness-collapse-0"> Timeliness </button>
            </div>
            <div id="timeliness-collapse-0" className="accordion-collapse collapse" aria-labelledby="timeliness-0">
              <div className="card-body">
                <p>BCC strives for punctuality but will not accept liability for any delays due to unforeseen circumstances.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="pets-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#pets-collapse-0"
                aria-expanded="true" aria-controls="pets-collapse-0"> Pets </button>
            </div>
            <div id="pets-collapse-0" className="accordion-collapse collapse" aria-labelledby="pets-0">
              <div className="card-body">
                <p>Pets are not allowed in vehicles, except for service animals, which must be accompanied by a mat or blanket.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="quotations-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#quotations-collapse-0"
                aria-expanded="true" aria-controls="quotations-collapse-0"> Quotations </button>
            </div>
            <div id="quotations-collapse-0" className="accordion-collapse collapse" aria-labelledby="quotations-0">
              <div className="card-body">
                <p>Quoted prices are subject to availability at the time of booking and include GST. BCC reserves the right to adjust prices based on any changes in airport charges, tolls, or fees. Waiting time fees may apply for airport pickups if passengers are delayed.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="seat-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#seat-collapse-0"
                aria-expanded="true" aria-controls="seat-collapse-0"> Seat Belts </button>
            </div>
            <div id="seat-collapse-0" className="accordion-collapse collapse" aria-labelledby="seat-0">
              <div className="card-body">
                <p>It is mandatory under Australian law for all passengers to wear seat belts while the vehicle is in motion.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="smoking-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#smoking-collapse-0"
                aria-expanded="true" aria-controls="smoking-collapse-0"> Smoking Policy </button>
            </div>
            <div id="smoking-collapse-0" className="accordion-collapse collapse" aria-labelledby="smoking-0">
              <div className="card-body">
                <p>Smoking is prohibited in all BCC vehicles, and charges may apply if this rule is violated.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="cancellation-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#cancellation-collapse-0"
                aria-expanded="true" aria-controls="cancellation-collapse-0"> Cancellation Policy </button>
            </div>
            <div id="cancellation-collapse-0" className="accordion-collapse collapse" aria-labelledby="cancellation-0">
              <div className="card-body">
                <p>Cancellation fees apply for various timeframes depending on the vehicle type and booking specifics. Special cancellation policies apply for events and weddings.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="waiting-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#waiting-collapse-0"
                aria-expanded="true" aria-controls="waiting-collapse-0"> Waiting Time </button>
            </div>
            <div id="waiting-collapse-0" className="accordion-collapse collapse" aria-labelledby="waiting-0">
              <div className="card-body">
                <p>BCC offers complimentary waiting times for all bookings, with specified limits based on pickup location.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="holidays-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#holidays-collapse-0"
                aria-expanded="true" aria-controls="holidays-collapse-0"> Public Holidays </button>
            </div>
            <div id="holidays-collapse-0" className="accordion-collapse collapse" aria-labelledby="holidays-0">
              <div className="card-body">
                <p>Surcharges may be applicable on public holidays, particularly on Christmas Day and New Year's Eve/Day.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="afterhours-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#afterhours-collapse-0"
                aria-expanded="true" aria-controls="afterhours-collapse-0"> After Hours Surcharges </button>
            </div>
            <div id="afterhours-collapse-0" className="accordion-collapse collapse" aria-labelledby="afterhours-0">
              <div className="card-body">
                <p>A surcharge applies for bookings made between 10 PM and 5 AM.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="restraints-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#restraints-collapse-0"
                aria-expanded="true" aria-controls="restraints-collapse-0"> Child Restraints </button>
            </div>
            <div id="restraints-collapse-0" className="accordion-collapse collapse" aria-labelledby="restraints-0">
              <div className="card-body">
                <p>All children under 8 years must be secured in an appropriate child restraint. A relocation and fitting fee applies per booking. Child restraints will no longer be provided in sedans.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="trailers-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#trailers-collapse-0"
                aria-expanded="true" aria-controls="trailers-collapse-0"> Trailers </button>
            </div>
            <div id="trailers-collapse-0" className="accordion-collapse collapse" aria-labelledby="trailers-0">
              <div className="card-body">
                <p>A fee will be charged for trailer use, and if luggage exceeds vehicle capacity, additional transport will be at the hirer's cost.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="limits-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#limits-collapse-0"
                aria-expanded="true" aria-controls="limits-collapse-0"> Limits of Liability & Insurance </button>
            </div>
            <div id="limits-collapse-0" className="accordion-collapse collapse" aria-labelledby="limits-0">
              <div className="card-body">
                <p>Our maximum liability, if accepted, will be limited to a refund for the affected trip. No claims for consequential damages will be accepted.</p>
              </div>
            </div>
        </div>
        </div>

        <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="policy-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#policy-collapse-0"
                aria-expanded="true" aria-controls="policy-collapse-0"> Privacy Policy </button>
            </div>
            <div id="policy-collapse-0" className="accordion-collapse collapse" aria-labelledby="policy-0">
              <div className="card-body">
                <p>Your personal information is used solely for booking services with BCC. It will not be shared with third parties except as necessary to fulfill service obligations. For more details, please refer to our privacy policy on our website.</p>
              </div>
            </div>
        </div>
        </div>

 {/*

      <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="events-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#events-collapse-0"
                aria-expanded="true" aria-controls="events-collapse-0"> Special Event Bookings </button>
            </div>
            <div id="events-collapse-0" className="accordion-collapse collapse" aria-labelledby="events-0">
              <div className="card-body">
                <p>Full payment or a deposit is required for special occasions, such as weddings and events. Additional surcharges may apply.</p>
              </div>
            </div>
        </div>
        </div>
      
      <div className="accordion accordion-wrapper mt-10" id="accordion">
        <div className="card accordion-item">
          <div className="card-header" id="subcontracting-0">
              <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#subcontracting-collapse-0"
                aria-expanded="true" aria-controls="subcontracting-collapse-0"> Subcontracting </button>
            </div>
            <div id="subcontracting-collapse-0" className="accordion-collapse collapse" aria-labelledby="subcontracting-0">
              <div className="card-body">
                <p>BCC reserves the right to utilize approved service providers as needed. These providers will remain under BCC's supervision during the booking period.</p>
              </div>
            </div>
        </div>
        </div>
    */}

        </section>
        </div>

        </div>
        </div>
      
  );
};

export default TermsAndConditions;
