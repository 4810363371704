import { isNullOrUndefinedOrEmpty, isPickupTimeWithInOneHour, isValidEmail } from "../common/utilfn";

export function validateGeneralDetailsPage(bookingDetails, setBookingErrors) {

    let errorFlag = true;
    if (isNullOrUndefinedOrEmpty(bookingDetails.generalDetails.bookingTime)) {
        setErrors('bookingTime', 'Booking date must be at least one hour from now.', setBookingErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.location.fromPlaceId)) {
        setErrors('fromPlaceId', 'Pickup location is required.', setBookingErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.location.toPlaceId)) {
        setErrors('toPlaceId', 'Drop location is required.', setBookingErrors);
        errorFlag = false;
    }
    if (bookingDetails.location.stopLocationFlag) {
        if (isNullOrUndefinedOrEmpty(bookingDetails.location.stopPlaceId)) {
            setErrors('stopPlaceId', 'Since you have selected, add another stop, Stop location is required.', setBookingErrors);
            errorFlag = false;
        }
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.generalDetails.bookingDate)) {
        setErrors('bookingDate', 'Booking date is required.', setBookingErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.generalDetails.bookingTime)) {
        setErrors('bookingTime', 'Booking time is required.', setBookingErrors);
        errorFlag = false;
    } else if (isPickupTimeWithInOneHour(bookingDetails.generalDetails.bookingTime, bookingDetails.generalDetails.bookingDate)) {
        setErrors('bookingTime', 'Booking must be at least one hour from now.', setBookingErrors);
        errorFlag = false;
    }
    if (bookingDetails.generalDetails.passengersCount <= 0) {
        setErrors('passengersCount', 'Number of passengers is required.', setBookingErrors);
        errorFlag = false;
    }
    if (bookingDetails.generalDetails.byTheHourCheck) {
        if (bookingDetails.generalDetails.numberOfHours <= 0) {
            setErrors('numberOfHours', 'Since you have opted booking by the hours, Number of hours is required.', setBookingErrors);
            errorFlag = false;
        }
    }
    if (bookingDetails.generalDetails.specialInstructions) {
        const regex = /^[a-zA-Z0-9,;_ ]*$/;
        if (!regex.test(bookingDetails.generalDetails.specialInstructions)) {
            setErrors('specialInstructions', 'Only alphanumeric characters, commas, semicolons, and underscores are allowed.', setBookingErrors);
            errorFlag = false;
        }

    }
    //specialInstructions
    return errorFlag;
}

export function validateVehicleSelectionPage(bookingDetails, setBookingErrors) {

    let errorFlag = true;
    if (isNullOrUndefinedOrEmpty(bookingDetails.selectedVehicleId)) {
        setErrors('selectedVehicleId', 'Please select the vehicle for your ride.', setBookingErrors);
        errorFlag = false;
    }

    return errorFlag;
}

const countryCodeRegex = /^\+\d+$/;
const mobileNumberRegex = /^\d+$/;
const countryCodeMinLength = 2;
const countryCodeMaxLength = 6;
const mobileNumberMinLength = 8;
const mobileNumberMaxLength = 15;


export function validatePassengerDetailsPage(bookingDetails, setBookingErrors) {

    let errorFlag = true;
    if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.firstName)) {
        setErrors('firstName', 'Firstname is required.', setBookingErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.lastName)) {
        setErrors('lastName', 'Lastname is required.', setBookingErrors);
        errorFlag = false;
    }
    if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.email)) {
        setErrors('email', 'Email is required.', setBookingErrors);
        errorFlag = false;
    } else if (!isValidEmail(bookingDetails.passengerDetails.email)) {
        setErrors('email', 'Please enter the valid email address.', setBookingErrors);
        errorFlag = false;
    }

    if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.countryCode)) {
        setErrors('countryCode', 'Country code is required.', setBookingErrors);
        errorFlag = false;
    } else if (!countryCodeRegex.test(bookingDetails.passengerDetails.countryCode)) {
        setErrors('countryCode', 'Invalid country code. Must start with + and contain digits.', setBookingErrors);
        errorFlag = false;
    } else if (bookingDetails.passengerDetails.countryCode.length < countryCodeMinLength || bookingDetails.passengerDetails.countryCode.length > countryCodeMaxLength) {
        setErrors('countryCode', `Country code length must be between ${countryCodeMinLength} and ${countryCodeMaxLength} characters.`, setBookingErrors);
        errorFlag = false;
    }

    if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.mobileNumber)) {
        setErrors('mobileNumber', 'Phone number is required.', setBookingErrors);
        errorFlag = false;
    } else if (!mobileNumberRegex.test(bookingDetails.passengerDetails.mobileNumber)) {
        setErrors('mobileNumber', 'Invalid mobile number. Must contain digits only.', setBookingErrors);
        errorFlag = false;
    } else if (bookingDetails.passengerDetails.mobileNumber.length < mobileNumberMinLength || bookingDetails.passengerDetails.mobileNumber.length > mobileNumberMaxLength) {
        setErrors('mobileNumber', `Mobile number length must be between ${mobileNumberMinLength} and ${mobileNumberMaxLength} digits.`, setBookingErrors);
        errorFlag = false;
    }

    if (bookingDetails.passengerDetails.onBehalfCheck) {
        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.firstName)) {
            setErrors('bookingPersonFirstName', 'Firstname is required.', setBookingErrors);
            errorFlag = false;
        }
        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.lastName)) {
            setErrors('bookingPersonLastName', 'Lastname is required.', setBookingErrors);
            errorFlag = false;
        }
        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.email)) {
            setErrors('bookingPersonEmail', 'Email is required.', setBookingErrors);
            errorFlag = false;
        } else if (!isValidEmail(bookingDetails.passengerDetails.bookingPerson.email)) {
            setErrors('bookingPersonEmail', 'Please enter the valid email address.', setBookingErrors);
            errorFlag = false;
        }
        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.mobileNumber)) {
            setErrors('bookingPersonMobileNumber', 'Phone number is required.', setBookingErrors);
            errorFlag = false;
        }

        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.countryCode)) {
            setErrors('bookingPersonCountryCode', 'Country code is required.', setBookingErrors);
            errorFlag = false;
        } else if (!countryCodeRegex.test(bookingDetails.passengerDetails.bookingPerson.countryCode)) {
            setErrors('bookingPersonCountryCode', 'Invalid country code. Must start with + and contain digits.', setBookingErrors);
            errorFlag = false;
        } else if (bookingDetails.passengerDetails.bookingPerson.countryCode.length < countryCodeMinLength || bookingDetails.passengerDetails.bookingPerson.countryCode.length > countryCodeMaxLength) {
            setErrors('bookingPersonCountryCode', `Country code length must be between ${countryCodeMinLength} and ${countryCodeMaxLength} characters.`, setBookingErrors);
            errorFlag = false;
        }

        if (isNullOrUndefinedOrEmpty(bookingDetails.passengerDetails.bookingPerson.mobileNumber)) {
            setErrors('bookingPersonMobileNumber', 'Phone number is required.', setBookingErrors);
            errorFlag = false;
        } else if (!mobileNumberRegex.test(bookingDetails.passengerDetails.bookingPerson.mobileNumber)) {
            setErrors('bookingPersonMobileNumber', 'Invalid mobile number. Must contain digits only.', setBookingErrors);
            errorFlag = false;
        } else if (bookingDetails.passengerDetails.bookingPerson.mobileNumber.length < mobileNumberMinLength || bookingDetails.passengerDetails.bookingPerson.mobileNumber.length > mobileNumberMaxLength) {
            setErrors('bookingPersonMobileNumber', `Mobile number length must be between ${mobileNumberMinLength} and ${mobileNumberMaxLength} digits.`, setBookingErrors);
            errorFlag = false;
        }

    }
    return errorFlag;
}

function setErrors(fieldName, errorMessage, setBookingErrors) {
    setBookingErrors(prevErrors => ({
        ...prevErrors,
        [fieldName]: errorMessage
    }));
}
