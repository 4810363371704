import React, { useState, useEffect } from 'react';
import { saveFeedbackMessage } from '../../db/feedbackDAO';
import { useAuth } from '../../contexts/authContext';
import { getUserById } from '../../db/userDAO';
import { validateFeedbackFormData } from '../validation/feedbackFormValidation';

function FeedbackContact() {

    const { currentUser } = useAuth();
    let initialFormState = {
        userId: '',
        firstName: '',
        lastName: '',
        subject: '',
        message: '',
        contact: {
            email: '',
            phone: ''
        }
    }

    const [formData, setFormData] = useState(initialFormState);
    const [successMessage, setSuccessMessage] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const userData = await getUserById(currentUser.uid);

                    setFormData({
                        userId: currentUser.uid || '',
                        firstName: userData.firstName || '',
                        lastName: userData.lastName || '',
                        subject: '',
                        message: '',
                        contact: {
                            email: userData.email || '',
                            phone: ''
                        }
                    });
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };
        fetchUserData();
    }, []);

    const textareaStyles = {
        height: '150px'
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const setContactDetails = (e) => {
        setFormData({
            ...formData,
            contact: {
                ...formData.contact,
                [e.target.name]: e.target.value
            },
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let flag = validateFormdata();
        if (!flag) {
            return;
        }

        let responseId = saveFeedbackMessage(formData);
        if (responseId) {
            setSuccessMessage("Your feedback has been submitted succesfully. Business Chauffeur team will contact you shortly!");
        }
        setFormData(initialFormState);
    };

    function validateFormdata() {
        setValidationErrors({});

        let errorFlag = true;
        if (!validateFeedbackFormData(formData, setValidationErrors)) {
            errorFlag = false;
        }
        return errorFlag;
    }

    return (
        <section className="wrapper bg-light">
            <div className="container pb-14 pb-md-16">
                <div className="row">
                    <div className="col mt-n12 mb-16">
                        <div className="card shadow-lg">
                            <div className="row gx-0">
                                <div className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-none d-md-block"
                                    data-image-src="./assets/img/photos/tm1.jpg" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                </div>
                                <div className="col-lg-6" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                    <div className="p-10 p-md-11 p-lg-13">
                                        <h2 className="display-4 mb-3">Feedback & Complaints</h2>
                                        <p className="lead fs-lg">If we haven’t met your expectations, we would like to know.</p>
                                        <p>Your feedback is important to us and helps us improve our services. Please click the below form
                                            to provide your details, and the team will be in touch within 48 hours:</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 mx-auto">
                        <div className="row gy-10 gx-lg-8 gx-xl-12">
                            <div className="col-lg-8" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                <form onSubmit={handleSubmit} className="contact-form needs-validation" method="post" action="#" noValidate>
                                    <div className="messages"></div>
                                    <div className="row gx-4">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="firstName" type="text" name="firstName" className="form-control"
                                                    value={formData.firstName}
                                                    onChange={handleChange}
                                                    required />
                                                <label htmlFor="firstName">First Name *</label>
                                                {validationErrors.firstName && (
                                                    <div class="text-danger"> {validationErrors.firstName}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="lastName" type="text" name="lastName" className="form-control"
                                                    value={formData.lastName}
                                                    onChange={handleChange}
                                                    required />
                                                <label htmlFor="lastName">Last Name *</label>
                                                {validationErrors.lastName && (
                                                    <div class="text-danger"> {validationErrors.lastName}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="email" type="email" name="email" className="form-control"
                                                    value={formData.contact.email}
                                                    onChange={setContactDetails}
                                                    required />
                                                <label htmlFor="email">Email *</label>
                                                {validationErrors.email && (
                                                    <div class="text-danger"> {validationErrors.email}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating mb-4">
                                                <input id="phone" type="tel" name="phone" className="form-control" placeholder="Contact Number"
                                                    value={formData.contact.phone}
                                                    onChange={setContactDetails}
                                                    required
                                                />
                                                <label htmlFor="phone">Contact Number *</label>
                                                {validationErrors.phone && (
                                                    <div class="text-danger"> {validationErrors.phone}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-4">
                                                <input id="subject" type="text" name="subject" className="form-control"
                                                    placeholder="Subject Line"
                                                    value={formData.subject}
                                                    onChange={handleChange}
                                                    required />
                                                <label htmlFor="subject">Subject Line *</label>
                                                {validationErrors.subject && (
                                                    <div class="text-danger"> {validationErrors.subject}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating mb-4">
                                                <textarea id="message" name="message" className="form-control" placeholder="Your message"
                                                    style={textareaStyles}
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required></textarea>
                                                <label htmlFor="message">Message *</label>
                                                {validationErrors.message && (
                                                    <div class="text-danger"> {validationErrors.message}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <input type="button" onClick={handleSubmit} className="btn btn-secondary rounded btn-send mb-3" value="Send message" />
                                            <p className="text-muted"><strong>*</strong> These fields are required.</p>
                                        </div>
                                        {successMessage && <div className="alert alert-success" role="alert"> {successMessage} </div>}
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4">
                                <div className="d-flex flex-row" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-location-pin-alt"></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Address</h5>
                                        <address>{process.env.REACT_APP_BUSINESS_ADDRESS}</address>
                                    </div>
                                </div>
                                <div className="d-flex flex-row" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-phone-volume"></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">Phone</h5>
                                        <p>{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</p>
                                    </div>
                                </div>
                                <div className="d-flex flex-row" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                                    <div>
                                        <div className="icon text-secondary fs-28 me-4 mt-n1"> <i className="uil uil-envelope"></i> </div>
                                    </div>
                                    <div>
                                        <h5 className="mb-1">E-mail</h5>
                                        <p className="mb-0">
                                            <a href={`mailto:${process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}`} className="link-body">
                                            {process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeedbackContact;