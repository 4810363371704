import { db } from '../config/firebase-config';
import { doc, addDoc, updateDoc, collection, Timestamp, getDocs, query, where } from 'firebase/firestore';
import * as FIREBASE_PATHS from '../config/firebase-path-mapping';

const EMPTY_OBJECT = {};

export async function saveBookingDetails(details) {

    try {
        const now = Timestamp.fromDate(new Date());
        details.generalDetails.creationDate = now;
        details.bookingReferenceId = Date.now().toString(36).toUpperCase();
        const tenantBookingCollection = collection(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH);
        let response = await addDoc(tenantBookingCollection, details);

        // Set the data at the reference created by the combined ID
        /*
        const docRef = doc(db, FIREBASE_PATHS.getBookingCollectionPathWithBookingId(bookingId));
        setDoc(docRef, details)
            .then(() => {
                console.log("Booking details successfully written!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
                return -1;
            });
        */

        console.log('Booking details saved successfully for the booking ID: ' + response.id);
        return response.id;
    } catch (error) {
        console.error('Error saving booking details', error);
        return -1;
    }
};

export async function updateBookingWithPaymentReference(details, bookingRefId, paymentRefId) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'CONFIRMED',
        paymentDetails: {
            paymentRefId: paymentRefId,
            amount: details.bookingFee,
            currency: details.paymentDetails.currency
        }
    });
}

export async function updateBookingWithRejectedPaymentStatus(bookingRefId, paymentDetails) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'REJECTED',
        paymentDetails
    });
}

export async function updateBookingWithCancelledPaymentStatusAndRefund(bookingRefId, refundAmount) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'CANCELLED',
        refundAmount: refundAmount
    });
}

export async function updateBookingWithCancelledPaymentStatus(bookingRefId) {
    const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
    await updateDoc(bookingByIdRef, {
        bookingStatus: 'CANCELLED'
    });
}

export async function getBookingByRefIdAndEmail(bookingRefId, emailId) {
    try {

        const bookingsCollection = collection(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH);

        // Create a query to filter by status and nested emailId
        const q = query(
            bookingsCollection,
            where('bookingReferenceId', '==', bookingRefId),
            where('passengerDetails.email', '==', emailId)
        );

        // Execute the query
        const querySnapshot = await getDocs(q);

        // Check if any documents were found
        if (querySnapshot.empty) {
            console.log('No matching bookings found.');
            return [];
        }

        // Process the documents
        const bookings = [];
        querySnapshot.forEach((doc) => {
            bookings.push({ id: doc.id, ...doc.data() });
        });

        return bookings;
        /*
        const bookingByIdRef = doc(db, FIREBASE_PATHS.BOOKING_COLLECTION_PATH, bookingRefId);
        const document = await getDoc(bookingByIdRef);
        return document.data();
        */

    } catch (error) {
        console.error('getDocumentById: Exception occured while fetching the data ', error);
    }
    return EMPTY_OBJECT;
}