import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import ManageBooking from './manage-booking';


function MainManageBooking() {
    return (
        <div className="content-wrapper">
            <Menu />
            <ManageBooking />
            <Footer />

        </div>
    );
}

export default MainManageBooking;