import React from 'react';
import { Link } from 'react-router-dom';

function HeroJoinFleet() {
  return (
    <section className="wrapper image-wrapper bg-image bg-overlay text-white" data-image-src="./assets/img/photos/bg1.jpg">
      <div className="container py-12 position-relative">
        <div className="row gx-0 align-items-center">
          <div className="col-12 col-md-6 text-start" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="py-1 py-md-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                  <li className="breadcrumb-item active text-muted" aria-current="page">Join The Fleet</li>
                </ol>
              </nav>
            </div>
            <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0 text-white">Join The Fleet</h1>
            <p className="fs-15 mb-4">Are you looking to join Australia’s largest and only national
              chauffeur fleet?</p>
            <div className="d-flex justify-content-start mb-5 gap-2 flex-column flex-md-row"
              data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
              <span>
                <Link to="/chauffeur" className="btn d-block btn-sm btn-secondary rounded m-0 me-md-2">JOIN AS A CHAUFFEUR</Link>
              </span>
              <span><a className="btn d-block btn-sm btn-light text-dark rounded">JOIN AS A CONTRACTOR</a></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroJoinFleet;