
const calculateDistanceFare = (category, distance) => {
    let amount;
    const { basePrice, minimumDistanceForBasePrice = 10, perkmSurcharge } = category;

    if (distance <= minimumDistanceForBasePrice) {
        amount = basePrice;
    } else {
        amount = basePrice + (distance - minimumDistanceForBasePrice) * perkmSurcharge;
    }
    return parseFloat(amount.toFixed(2));
};

const calculateHourlyFare = (category, bookingDetails, distance) => {
    let amount;
    const { hourlyRate, minimumDistanceForHourlyRate = 20, perkmSurcharge } = category;
    let totalBookingHours = bookingDetails.generalDetails.numberOfHours;

    if (distance <= minimumDistanceForHourlyRate) {
        amount = hourlyRate * totalBookingHours;
    } else {
        amount = (hourlyRate * totalBookingHours) + (distance - minimumDistanceForHourlyRate) * perkmSurcharge;
    }
    return parseFloat(amount.toFixed(2));
};


const calculateFare = (category, bookingDetails) => {
    let distaceValue = bookingDetails.distanceMatrix.distance.value;
    if (distaceValue && distaceValue !== 0) {
        distaceValue = distaceValue / 1000;
        if (bookingDetails.generalDetails.byTheHourCheck) {
            return calculateHourlyFare(category, bookingDetails, distaceValue);
        } else {
            return calculateDistanceFare(category, distaceValue);
        }
    }
};

module.exports = {
    calculateFare,
    calculateDistanceFare,
    calculateHourlyFare
};
