import {React, useEffect} from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroAirportTransfers from './hero-airport-transfers';
import DistinguishedFleet from '../general/distinguished-fleet';
import FleetRange from '../general/fleet-range';


function MainAirportTransfers() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroAirportTransfers />
      <DistinguishedFleet />
      <FleetRange />
      <Footer />
    </div>
  );
}

export default MainAirportTransfers;