import * as FIREBASE_PATHS from '../config/firebase-path-mapping';
import * as genericDAO from './genericDAO';
import { where } from 'firebase/firestore';

export async function getVehicleCategoryTest() {
    let c = await getVehicleCategories();
    let c2 = await getVehicleCategoryById(c[0].id);

    let c3 = await getVehicleTypes();
    let c4 = await getVehicleTypeById(c3[0].id);

    let c5 = await getVehicleTypeByCondition();
    console.log('done');
}

export async function getVehicleCategories() {
    return await genericDAO.getDocuments(FIREBASE_PATHS.VEHICLE_CATEGORY_COLLECTION_PATH);
}

export async function udpateVehicleCategoriesWithBookingFee(bookingFee) {
    const categories = await getVehicleCategories();
    categories.forEach((category) => {
        if(bookingFee && bookingFee != 0){
            category.basePrice = category.basePrice * bookingFee;
        }
        
    });
    return categories;
}

export async function getVehicleTypeByCondition(vehicleCategoryId) {
    let conditions = [
        where("isArchived", "==", false)
    ];
    const vehicleTypePath = FIREBASE_PATHS.getVehicleTypeCollectionPath(vehicleCategoryId);
    return await genericDAO.getDocumentsByCriteria(vehicleTypePath, conditions);
}

export async function getFleet() {
    const categories = await getVehicleCategories();
    const fleetData = await Promise.all(categories.map(async (category) => {
        const vehicleTypes = await getVehicleTypeByCondition(category.id);
        return {
            ...category,
            vehicleTypes
        };
    }));
    return fleetData;
}

/*
export async function getVehicleTypeByCondition() {
    let conditions = [];
    conditions.push(where("isArchived", "==", false));
    return await genericDAO.getDocumentsByCriteria(FIREBASE_PATHS.VEHICLE_TYPE_COLLECTION_PATH, conditions);
}*/

export async function getVehicleCategoryById(categoryId) {
    return await genericDAO.getDocumentById(FIREBASE_PATHS.VEHICLE_CATEGORY_COLLECTION_PATH, categoryId.toString())
}

export async function getVehicleTypes() {
    return await genericDAO.getDocuments(FIREBASE_PATHS.VEHICLE_TYPE_COLLECTION_PATH);
}

export async function getVehicleTypeById(typeId) {
    return await genericDAO.getDocumentById(FIREBASE_PATHS.VEHICLE_TYPE_COLLECTION_PATH, typeId.toString())
}
