import React, { useContext, useEffect } from 'react';
import { BookingContext } from '../../../contexts/bookingContext';
import { useAuth } from '../../../contexts/authContext';
import { getUserById } from '../../../db/userDAO';

function BookingPassengerDetails() {

    let { bookingDetails, setBookingDetails, bookingErrors } = useContext(BookingContext);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const userData = await getUserById(currentUser.uid);

                    setBookingDetails((prevDetails) => ({
                        ...prevDetails,
                        passengerDetails: {
                            ...prevDetails.passengerDetails,
                            firstName: userData.firstName || '',
                            lastName: userData.lastName || '',
                            email: userData.email || '',
                        }
                    }));
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        };
        fetchUserData();
    }, []);

    const handlePassengersFirstNameChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                firstName: event.target.value
            }
        }));
    };

    const handlePassengersLastNameChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                lastName: event.target.value
            }
        }));
    };


    // Handler for updating country code
    const handleCountryCodeChange = (e) => {
        const newCountryCode = e.target.value;
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                countryCode: newCountryCode,
            },
        }));
    };

    const handlePassengersMobileNumberChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                mobileNumber: event.target.value
            }
        }));
    };

    const handlePassengersEmailChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                email: event.target.value
            }
        }));
    };

    const handlePassengersOnBehalfCheckChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                onBehalfCheck: event.target.checked
            }
        }));

        if (!event.target.checked) {
            setBookingDetails((prevDetails) => ({
                ...prevDetails,
                passengerDetails: {
                    ...prevDetails.passengerDetails,
                    bookingPerson: {
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobileNumber: ''
                    }
                }
            }));
        }
    };

    const handleBookingPersonFirstNameChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                bookingPerson: {
                    ...prevDetails.passengerDetails.bookingPerson,
                    firstName: event.target.value
                }
            }
        }));
    };

    const handleBookingPersonLastNameChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                bookingPerson: {
                    ...prevDetails.passengerDetails.bookingPerson,
                    lastName: event.target.value
                }
            }
        }));
    };

    //handleBookingPersonCountryCodeChange
    const handleBookingPersonCountryCodeChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                bookingPerson: {
                    ...prevDetails.passengerDetails.bookingPerson,
                    countryCode: event.target.value
                }
            }
        }));
    };


    const handleBookingPersonMobileNumberChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                bookingPerson: {
                    ...prevDetails.passengerDetails.bookingPerson,
                    mobileNumber: event.target.value
                }
            }
        }));
    };

    const handleBookingPersonEmailChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            passengerDetails: {
                ...prevDetails.passengerDetails,
                bookingPerson: {
                    ...prevDetails.passengerDetails.bookingPerson,
                    email: event.target.value
                }
            }
        }));
    };

    return (
        <fieldset className="step" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="container">
                <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">

                    <div className="col-12">
                        <div className="form-heading">
                            <h3>PASSENGER DETAILS</h3>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="First Name" id="firstName"
                                value={bookingDetails.passengerDetails.firstName}
                                onChange={handlePassengersFirstNameChange} />
                            <label htmlFor="firstName">First Name</label>
                            {bookingErrors.firstName && (
                                <div class="text-danger"> {bookingErrors.firstName}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Last Name" id="lastName"
                                value={bookingDetails.passengerDetails.lastName}
                                onChange={handlePassengersLastNameChange} />
                            <label htmlFor="lastName">Last Name</label>
                            {bookingErrors.lastName && (
                                <div class="text-danger"> {bookingErrors.lastName}</div>
                            )}
                        </div>
                    </div>
                    {/*
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Enter mobile number" id="mobileNumber"
                                value={bookingDetails.passengerDetails.mobileNumber}
                                onChange={handlePassengersMobileNumberChange}
                            />
                            <label htmlFor="mobileNumber">Enter mobile number</label>
                            {bookingErrors.mobileNumber && (
                                <div class="text-danger"> {bookingErrors.mobileNumber}</div>
                            )}
                        </div>
                    </div>

                    */}
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            {/* Flex container for Country Code and Mobile Number */}
                            <div className="d-flex align-items-center">
                                {/* Country Code Input */}
                                <div style={{ flex: '0 0 160px' }} className="form-floating me-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="countryCode"

                                        value={bookingDetails.passengerDetails.countryCode || '+61'}
                                        onChange={handleCountryCodeChange}
                                    />
                                    <label htmlFor="countryCode">Country Code</label>

                                </div>

                                {/* Mobile Number Input */}
                                <div style={{ flex: '1 1 auto' }} className="form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobileNumber"
                                        placeholder="Enter mobile number"
                                        value={bookingDetails.passengerDetails.mobileNumber}
                                        onChange={handlePassengersMobileNumberChange}
                                    />
                                    <label htmlFor="mobileNumber">Mobile Number</label>

                                </div>
                            </div>
                            {bookingErrors.mobileNumber && (
                                <div className="text-danger">{bookingErrors.mobileNumber}</div>
                            )}
                            {bookingErrors.countryCode && (
                                <div className="text-danger">{bookingErrors.countryCode}</div>
                            )}
                            {/* Display error message for mobile number if any */}

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="email" className="form-control" placeholder="Enter email" id="email"
                                value={bookingDetails.passengerDetails.email}
                                onChange={handlePassengersEmailChange}
                            />
                            <label htmlFor="email">Enter email</label>
                            {bookingErrors.email && (
                                <div class="text-danger"> {bookingErrors.email}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input" type="checkbox" role="switch" id="booking-on-behalf"
                                onChange={handlePassengersOnBehalfCheckChange}
                            />
                            <label className="form-check-label" htmlFor="booking-on-behalf">Booking on behalf of someone else?</label>
                        </div>
                    </div>
                    {bookingDetails.passengerDetails.onBehalfCheck && (
                        <div id="bookingperson">
                            <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-5">
                                <div className="col-12">
                                    <div className="form-heading">
                                        <h3>YOUR DETAILS (PERSON BOOKING)</h3>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control" placeholder="First Name" id="fname"
                                            value={bookingDetails.passengerDetails.bookingPerson?.firstName || ''}
                                            onChange={handleBookingPersonFirstNameChange}
                                        />
                                        <label htmlFor="fname">First Name</label>
                                        {bookingErrors.bookingPersonFirstName && (
                                            <div class="text-danger"> {bookingErrors.bookingPersonFirstName}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control" placeholder="Last Name" id="lname"
                                            value={bookingDetails.passengerDetails.bookingPerson?.lastName || ''}
                                            onChange={handleBookingPersonLastNameChange}
                                        />
                                        <label htmlFor="lname">Last Name</label>
                                        {bookingErrors.bookingPersonLastName && (
                                            <div class="text-danger"> {bookingErrors.bookingPersonLastName}</div>
                                        )}
                                    </div>
                                </div>

                                {/*
                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        <input type="text" className="form-control" placeholder="Enter mobile number" id="bookingPersonMobileNumber"
                                            value={bookingDetails.passengerDetails.bookingPerson?.mobileNumber || ''}
                                            onChange={handleBookingPersonMobileNumberChange}
                                        />
                                        <label htmlFor="bookingPersonMobileNumber">Enter mobile number</label>
                                        {bookingErrors.bookingPersonMobileNumber && (
                                            <div class="text-danger"> {bookingErrors.bookingPersonMobileNumber}</div>
                                        )}
                                    </div>
                                </div>
*/}
                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        {/* Flex container for Country Code and Mobile Number */}
                                        <div className="d-flex align-items-center">
                                            {/* Country Code Input */}
                                            <div style={{ flex: '0 0 160px' }} className="form-floating me-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bookingPersonCountryCode"
                                                    value={bookingDetails.passengerDetails.bookingPerson?.countryCode || '+61'}
                                                    onChange={handleBookingPersonCountryCodeChange}
                                                />
                                                <label htmlFor="bookingPersonCountryCode">Country Code</label>
                                            </div>

                                            {/* Mobile Number Input */}
                                            <div style={{ flex: '1 1 auto' }} className="form-floating">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="bookingPersonMobileNumber"
                                                    placeholder="Enter mobile number"
                                                    value={bookingDetails.passengerDetails.bookingPerson?.mobileNumber}
                                                    onChange={handleBookingPersonMobileNumberChange}
                                                />
                                                <label htmlFor="bookingPersonMobileNumber">Mobile Number</label>

                                            </div>
                                        </div>
                                        {bookingErrors.bookingPersonMobileNumber && (
                                            <div className="text-danger">{bookingErrors.bookingPersonMobileNumber}</div>
                                        )}
                                        {bookingErrors.bookingPersonCountryCode && (
                                            <div className="text-danger">{bookingErrors.bookingPersonCountryCode}</div>
                                        )}
                                        {/* Display error message for mobile number if any */}

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-floating mb-4">
                                        <input type="email" className="form-control" placeholder="Enter email" id="bookingPersonEmail"
                                            value={bookingDetails.passengerDetails.bookingPerson?.email || ''}
                                            onChange={handleBookingPersonEmailChange}
                                        />
                                        <label htmlFor="bookingPersonEmail">Enter email</label>
                                        {bookingErrors.bookingPersonEmail && (
                                            <div class="text-danger"> {bookingErrors.bookingPersonEmail}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </fieldset>
    );
}

export default BookingPassengerDetails;