import React from 'react';

function DistinguishedFleet() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-10 pt-md-10 pb-14 pb-md-10">
                <div className="row text-center mb-4">
                    <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
                        data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-dark mb-2">Our Distinguished </h2>
                        <h3 className="display-3 mb-2">Discover Our Distinguished Fleet</h3>
                    </div>
                </div>
                <div className="row text-justify mb-0">
                    <div className="col-md-6 col-12 mx-auto position-relative" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <p className="fs-15">Our distinguished fleet of hired cars sets the stage for an extraordinary travel
                            experience. From elegant sedans to spacious SUVs, we offer a diverse selection of vehicles to cater to
                            your specific needs and preferences for Airport Transfers over to Luxury Tours.</p>
                        <p className="fs-15 mb-0">If you’re attending a corporate event, embarking on a leisurely getaway, or
                            celebrating a milestone, our extensive fleet ensures that you find the perfect ride for every occasion.
                            Impeccably maintained and equipped with cutting-edge amenities, our vehicles are driven by professional
                            chauffeurs who prioritize your safety and satisfaction. Get ready to indulge in a seamless journey as you
                            sit back, relax, and enjoy the epitome of sophistication on wheels.</p>
                    </div>
                    <div className="col-md-6 col-12 mx-auto position-relative" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <p className="fs-15">Our distinguished fleet of hired cars sets the stage for an extraordinary travel
                            experience. From elegant sedans to spacious SUVs, we offer a diverse selection of vehicles to cater to
                            your specific needs and preferences for Airport Transfers over to Luxury Tours.</p>
                        <p className="fs-15 mb-0">If you’re attending a corporate event, embarking on a leisurely getaway, or
                            celebrating a milestone, our extensive fleet ensures that you find the perfect ride for every occasion.
                            Impeccably maintained and equipped with cutting-edge amenities, our vehicles are driven by professional
                            chauffeurs who prioritize your safety and satisfaction. Get ready to indulge in a seamless journey as you
                            sit back, relax, and enjoy the epitome of sophistication on wheels.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DistinguishedFleet;