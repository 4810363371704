import { React, useEffect, useContext, useState } from 'react';
import BookingDetails from './bookingGeneralDetails';
import BookingVehicleSelection from './bookingVehicleSelection';
import BookingPassengerDetails from './bookingPassengerDetails';
import BookingSummary from './bookingSummary';
import BookingPaymentSummary from './bookingPaymentSummary';
import { BookingContext } from '../../../contexts/bookingContext';
import BookingModalOption from './bookingModalOption';
import { validateGeneralDetailsPage, validateVehicleSelectionPage, validatePassengerDetailsPage } from '../../validation/bookingValidation';

function BookingForm() {
    let { bookingDetails, setBookingDetails, bookingErrors, setBookingErrors, clearBookingForm } = useContext(BookingContext);
    const [prevButtonFlag, setPrevButtonFlag] = useState(true);

    {/*

    const textareaStyle = {
        width: '100%',
        height: '100%',
        border: '0'
    };
*/}


    useEffect(() => {
        // Dynamically load external scripts after component is mounted
        window.currentTab = bookingDetails.pageDisplay.currentTab;
        const initializeBookingMenu = async () => {

            if (bookingDetails.pageDisplay.currentTab === 0) {
                window.showTab(0, 0, 0);
            }

        };
        initializeBookingMenu();
    }, []);

    useEffect(() => {
        if (bookingDetails.isBookingLogged) {
            setPrevButtonFlag(false);
        } else {
            setPrevButtonFlag(true);
        }
    }, [bookingDetails.isBookingLogged]);

    const handleClickPrev = () => {
        //    window.nextPrev(-1);
        let currentTab = bookingDetails.pageDisplay.currentTab;

        window.nextPrev(currentTab, --currentTab, -1);

        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            pageDisplay: {
                ...prevDetails.pageDisplay,
                currentTab: currentTab,
            }
        }));
        window.scrollTo(0,0);
    };

    const clearFormData = () => {
        clearBookingForm();
    };

    const handleClickNext = () => {
        let currentTab = bookingDetails.pageDisplay.currentTab;

        let flag = validateBookingDetails();
        if (!flag) {
            return;
        }

        if (currentTab === 0) {
            setBookingDetails((prevDetails) => ({
                ...prevDetails,
                flagForDistanceCalculation: !bookingDetails.flagForDistanceCalculation
            }));
        }

        window.nextPrev(currentTab, ++currentTab, 1);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            pageDisplay: {
                ...prevDetails.pageDisplay,
                currentTab: currentTab,
            }
        }));
        window.scrollTo(0,0);
    };

    function validateBookingDetails() {
        setBookingErrors({});

        let errorFlag = true;
        if (bookingDetails.pageDisplay.currentTab == 0) {
            if (!validateGeneralDetailsPage(bookingDetails, setBookingErrors)) {
                errorFlag = false;
            }
        } else if (bookingDetails.pageDisplay.currentTab == 1) {
            if (!validateVehicleSelectionPage(bookingDetails, setBookingErrors)) {
                errorFlag = false;
            }
        } else if (bookingDetails.pageDisplay.currentTab == 2) {
            if (!validatePassengerDetailsPage(bookingDetails, setBookingErrors)) {
                errorFlag = false;
            }
        }
        if (!errorFlag) {
            // Find the first field with an error in bookingErrors
            const firstErrorField = Object.keys(bookingErrors).find((key) => bookingErrors[key]);
            
            if (firstErrorField) {
                const element = document.getElementById(firstErrorField);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }
            }
        }
        return errorFlag;
    }

    const getButtonLabelValue = () => {
        return window.getNextButtonLabel(bookingDetails.pageDisplay.currentTab);
    };

    return (
        <>
            <section className="wrapper bg-light">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center" data-cues="slideInDown"
                            data-delay={process.env.REACT_APP_DELAY}>
                            <h2 className="fs-16 text-uppercase text-dark mb-2">Book With Chauffeurs</h2>
                            <h3 className="display-3 mb-2"> Welcome to Chauffeurs</h3>
                            <p className="fs-15 m-0">Booking takes less than a minute.</p>
                        </div>
                    </div>
                </div>
                <form id="bookingForm">
                    <div className="container form-navigation d-block mb-5">
                        <ul id="progressbar">
                            <li className="stepIndicator active" id="General"><strong>General Details</strong>
                                {/* <span className="bar-line"></span>  */}
                            </li>
                            <li className="stepIndicator" id="Vehicle"><strong>Vehicle Selection</strong>
                                {/* <span className="bar-line"></span>  */}
                            </li>
                            <li className="stepIndicator" id="Passenger"><strong>Passenger Details</strong>
                                {/* <span className="bar-line"></span>  */}
                            </li>
                            <li className="stepIndicator" id="Summary"><strong>Summary</strong>
                                {/* <span className="bar-line"></span>  */}
                            </li>
                            <li className="stepIndicator" id="Payment"><strong>Payment</strong></li>
                        </ul>
                    </div>
                    <BookingDetails />
                    <BookingVehicleSelection />
                    <BookingPassengerDetails />
                    <BookingSummary />
                    <BookingPaymentSummary />


                    <div id="formfooter" className="sticky-bottom" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <div className="container">
                            <div className="d-flex justify-content-between align-items-center gap-1 py-4">

                                {prevButtonFlag && (
                                    <>
                                        <div className="text-sm-start text-center">
                                            <input type="reset" id="reset" className="reset btn btn-sm btn-primary rounded text-uppercase"
                                                onClick={clearFormData}
                                                value="Clear Form" />
                                            <input type="button" id="previous" onClick={handleClickPrev}
                                                className="previous btn btn-sm btn-primary rounded text-uppercase" value="Previous" />
                                        </div>

                                        <div className="text-center">
                                            <span className="fs-20"><strong className="text-primary">Total : </strong> <span
                                                className="text-navy"><i className="uil uil-dollar-sign"></i> {bookingDetails.bookingFee}</span> </span>
                                        </div>
                                    </>
                                )}
                                <div className="text-sm-end text-center">
                                    <input type="button" id="next" onClick={handleClickNext}
                                        className="next btn btn-sm btn-secondary rounded text-uppercase"
                                        value={getButtonLabelValue()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <BookingModalOption />
        </>
    );
}

export default BookingForm;