import React from 'react';

function Chauffeur() {
  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-10">
        <div className="row mb-5">
          <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center">
            <h2 className="fs-16 text-uppercase text-dark mb-2">Chauffeurs</h2>
            <h3 className="display-3 mb-2">Join The Fleet – Chauffeurs</h3>
            <p className="fs-15">Chauffeurs prides itself on hiring Australia’s most professional and reliable chauffeurs.
            </p>
          </div>
        </div>
        <form className="mb-3">
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-md-6" >
              <div className="form-select-wrapper mb-4">
                <select className="form-select" id="city" name="city" aria-label="city">
                  <option value="">City</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Lahore">Lahore</option>
                  <option value="Amritsar">Amritsar</option>
                  <option value="Islamabad">Islamabad</option>
                </select>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Do you have Chauffeur or Contracting Experience?"
                  id="experience" />
                <label for="experience">Do you have Chauffeur or Contracting Experience?</label>
              </div>
            </div>
          </div>
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12" >
              <div className="form-heading">
                <h3>Personal Details</h3>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="First Name" id="fname" />
                <label for="fname">First Name</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Last Name" id="lname" />
                <label for="lname">Last Name</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Year of Birth" id="YObirth" />
                <label for="YObirth">Year of Birth</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Nationality as per passport" id="nationality" />
                <label for="nationality">Nationality as per passport</label>
              </div>
            </div>
          </div>
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12" >
              <div className="form-heading">
                <h3>Driver Licence Details</h3>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-select-wrapper mb-4">
                <select className="form-select" aria-label="Driver Licence Type">
                  <option selected>Driver Licence Type</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Driver Licence Expiry" id="licence" />
                <label for="licence">Driver Licence Expiry</label>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Year of Birth" id="YObirth" />
                <label for="YObirth">Year of Birth</label>
              </div>
            </div>
          </div>
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12" >
              <div className="form-heading">
                <h3>Passenger Transport Driver Authorisation</h3>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-select-wrapper mb-4">
                <select className="form-select" id="authority" name="authority"
                  aria-label="Do You Have a Driver Authority?">
                  <option selected>Do You Have a Driver Authority?</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Driver Authority Expiry" id="expiry" />
                <label for="expiry">Driver Authority Expiry</label>
              </div>
            </div>
          </div>
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12" >
              <div className="form-heading">
                <h3>Address</h3>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Suburb" id="suburb" />
                <label for="suburb">Suburb</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="City" id="city" />
                <label for="city">City</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="State" id="state" />
                <label for="state">State</label>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Postcode" id="postcode" />
                <label for="postcode">Postcode</label>
              </div>
            </div>
          </div>
          <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12" >
              <div className="form-heading">
                <h3>Contact Details (So We Can Contact You)</h3>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-floating mb-4">
                <input type="email" className="form-control" placeholder="Email" id="email" />
                <label for="email">Email</label>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-floating mb-4">
                <input type="email" className="form-control" placeholder="Confirm Email" id="confimemail" />
                <label for="confimemail">Confirm Email</label>
              </div>
            </div>
            <div className="col-md-4" >
              <div className="form-floating mb-4">
                <input type="text" className="form-control" placeholder="Phone Number" id="phonenumber" />
                <label for="phonenumber">Phone Number</label>
              </div>
            </div>
          </div>
          <div className="row justify-content-center grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0 mb-4">
            <div className="col-12 text-center" >
              <input type="submit" className="btn btn-secondary rounded btn-login mb-2 text-uppercase" value="submit" />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Chauffeur;