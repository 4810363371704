import {React} from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import DistinguishedFleet from '../general/distinguished-fleet';
import FleetRange from '../general/fleet-range';
import HeroFleet from './hero-fleet';


function MainFleet() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroFleet />
      {/* <DistinguishedFleet /> */}
      <FleetRange />
      <Footer />
    </div>
  );
}

export default MainFleet;