import React, { useState, useEffect, useContext, useRef } from 'react';
import { BookingContext } from '../../../contexts/bookingContext';
import * as MapDataService from '../../../db/mapDataService';
import { isNullOrUndefinedOrEmpty } from '../../common/utilfn';

function BookingDetails() {

    let { bookingDetails, setBookingDetails, bookingErrors, setBookingErrors } = useContext(BookingContext);

    //    const [firstCheckFlag, setFirstCheckFlag] = useState(true);
    const [dropdownVisible, setDropdownVisible] = useState(true);
    const [dropdownVisibleForTo, setDropdownVisibleForTo] = useState(true);
    const [dropdownVisibleForStop, setDropdownVisibleForStop] = useState(true);
    const [dropdownVisibleForStop2, setDropdownVisibleForStop2] = useState(true);
    const [dropdownVisibleForStop3, setDropdownVisibleForStop3] = useState(true);
    const [dropdownVisibleForStop4, setDropdownVisibleForStop4] = useState(true);

    const [autoCompleteResults, setAutoCompleteResults] = useState([]);
    const [autoCompleteResultsForTo, setAutoCompleteResultsForTo] = useState([]);
    const [autoCompleteResultsForStop, setAutoCompleteResultsForStop] = useState([]);
    const [autoCompleteResultsForStop2, setAutoCompleteResultsForStop2] = useState([]);
    const [autoCompleteResultsForStop3, setAutoCompleteResultsForStop3] = useState([]);
    const [autoCompleteResultsForStop4, setAutoCompleteResultsForStop4] = useState([]);

    const suggestionFromContainerRef = useRef(null);
    const suggestionToContainerRef = useRef(null);
    const suggestionStopContainerRef = useRef(null);
    const suggestionStopContainerRef2 = useRef(null);
    const suggestionStopContainerRef3 = useRef(null);
    const suggestionStopContainerRef4 = useRef(null);

    const [time, setTime] = useState('10:00');

    const handleTimeChange = (newTime) => {
        setTime(newTime);
    };

    const setFromPlaceToBlank = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                fromPlaceId: '',
                pickupLocation: ''
            }
        }));
    }

    const setToPlaceToBlank = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                toPlaceId: '',
                dropLocation: ''
            }
        }));

    }

    const setStopPlaceToBlank = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId: '',
                stopLocation: ''
            }
        }));

    }
    const setStopPlaceToBlank2 = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId2: '',
                stopLocation2: ''
            }
        }));

    }

    const setStopPlaceToBlank3 = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId3: '',
                stopLocation3: ''
            }
        }));

    }
    const setStopPlaceToBlank4 = () => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId4: '',
                stopLocation4: ''
            }
        }));

    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.pickupLocation;
            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisible(false);
                setFromPlaceToBlank();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResults(addressPredictions);
        }, 300);

        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.pickupLocation]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.dropLocation;

            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisibleForTo(false);
                setToPlaceToBlank();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResultsForTo(addressPredictions);
        }, 300);
        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.dropLocation]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.stopLocation;
            if (!bookingDetails.location.stopLocationFlag) {
                return;
            }
            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisibleForStop(false);
                setStopPlaceToBlank();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResultsForStop(addressPredictions);
        }, 300);
        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.stopLocation]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.stopLocation2;
            if (!bookingDetails.location.stopLocationFlag2) {
                return;
            }
            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisibleForStop2(false);
                setStopPlaceToBlank2();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResultsForStop2(addressPredictions);
        }, 300);
        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.stopLocation2]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.stopLocation3;
            if (!bookingDetails.location.stopLocationFlag3) {
                return;
            }
            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisibleForStop3(false);
                setStopPlaceToBlank3();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResultsForStop3(addressPredictions);
        }, 300);
        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.stopLocation3]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(async () => {
            let val = bookingDetails.location.stopLocation4;
            if (!bookingDetails.location.stopLocationFlag4) {
                return;
            }
            if (isNullOrUndefinedOrEmpty(val)) {
                setDropdownVisibleForStop4(false);
                setStopPlaceToBlank4();
                return;
            }
            let addressPredictions = await MapDataService.fetchAddressData(val);
            setAutoCompleteResultsForStop4(addressPredictions);
        }, 300);
        // Cleanup the timeout on each input change
        return () => clearTimeout(delayDebounceFn);
    }, [bookingDetails.location.stopLocation4]);

    // This effect adds a click listener to close the dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionFromContainerRef.current && !suggestionFromContainerRef.current.contains(event.target)) {
                setDropdownVisible(false);
                setFromPlaceToBlank();
            }
            if (suggestionToContainerRef.current && !suggestionToContainerRef.current.contains(event.target)) {
                setDropdownVisibleForTo(false);
                setToPlaceToBlank();
            }
            if (suggestionStopContainerRef.current && !suggestionStopContainerRef.current.contains(event.target)) {
                setDropdownVisibleForStop(false);
                setStopPlaceToBlank();
            }
            if (suggestionStopContainerRef2.current && !suggestionStopContainerRef2.current.contains(event.target)) {
                setDropdownVisibleForStop2(false);
                setStopPlaceToBlank2();
            }
            if (suggestionStopContainerRef3.current && !suggestionStopContainerRef3.current.contains(event.target)) {
                setDropdownVisibleForStop3(false);
                setStopPlaceToBlank3();
            }
            if (suggestionStopContainerRef4.current && !suggestionStopContainerRef4.current.contains(event.target)) {
                setDropdownVisibleForStop4(false);
                setStopPlaceToBlank4();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const handleInputChange = (e) => {
        // setDropdownVisibleForStop(false);
        // setDropdownVisibleForTo(false);
        setDropdownVisible(true);
        // setFirstCheckFlag(false);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                fromLocationFlag: true,
                pickupLocation: e.target.value,
            }

        }));
    };

    const handleInputChangeForToLocation = (e) => {
        // setDropdownVisibleForStop(false);
        // setDropdownVisible(false);
        setDropdownVisibleForTo(true);
        // setFirstCheckFlag(false);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                fromLocationFlag: false,
                dropLocation: e.target.value,
            }
        }));
    };

    const handleInputChangeForStopLocation = (e) => {
        // setDropdownVisible(false);
        // setDropdownVisibleForTo(false);
        setDropdownVisibleForStop(true);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocation: e.target.value,
            }
        }));
    };

    const handleInputChangeForStopLocation2 = (e) => {
        // setDropdownVisible(false);
        // setDropdownVisibleForTo(false);
        setDropdownVisibleForStop2(true);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocation2: e.target.value,
            }
        }));
    };

    const handleInputChangeForStopLocation3 = (e) => {
        // setDropdownVisible(false);
        // setDropdownVisibleForTo(false);
        setDropdownVisibleForStop3(true);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocation3: e.target.value,
            }
        }));
    };

    const handleInputChangeForStopLocation4 = (e) => {
        // setDropdownVisible(false);
        // setDropdownVisibleForTo(false);
        setDropdownVisibleForStop4(true);
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocation4: e.target.value,
            }
        }));
    };

    const handleAddressSelection = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                fromPlaceId: placeId,
                pickupLocation: addressValue
            }
        }));
        setDropdownVisible(false);
        setAutoCompleteResults([]);
        // calculateDistance(placeId, bookingDetails.location.toPlaceId, bookingDetails.location.stopPlaceId);
    };

    const handleAddressSelectionForToLocation = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                toPlaceId: placeId,
                dropLocation: addressValue,
            }
        }));
        setDropdownVisibleForTo(false);
        setAutoCompleteResultsForTo([]);
        // calculateDistance(bookingDetails.location.fromPlaceId, placeId, bookingDetails.location.stopPlaceId);
    };

    const handleAddressSelectionForStopLocation = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId: placeId,
                stopLocation: addressValue,
            }
        }));
        setDropdownVisibleForStop(false);
        setAutoCompleteResultsForStop([]);
        // calculateDistance(bookingDetails.location.fromPlaceId, bookingDetails.location.toPlaceId, placeId);
    };

    const handleAddressSelectionForStopLocation2 = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId2: placeId,
                stopLocation2: addressValue,
            }
        }));
        setDropdownVisibleForStop2(false);
        setAutoCompleteResultsForStop2([]);
        // calculateDistance(bookingDetails.location.fromPlaceId, bookingDetails.location.toPlaceId, placeId);
    };

    const handleAddressSelectionForStopLocation3 = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId3: placeId,
                stopLocation3: addressValue,
            }
        }));
        setDropdownVisibleForStop3(false);
        setAutoCompleteResultsForStop3([]);
        // calculateDistance(bookingDetails.location.fromPlaceId, bookingDetails.location.toPlaceId, placeId);
    };

    const handleAddressSelectionForStopLocation4 = (placeId, addressValue) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopPlaceId4: placeId,
                stopLocation4: addressValue,
            }
        }));
        setDropdownVisibleForStop4(false);
        setAutoCompleteResultsForStop4([]);
        // calculateDistance(bookingDetails.location.fromPlaceId, bookingDetails.location.toPlaceId, placeId);
    };

    useEffect(() => {
        // This will run whenever bookingDetails.location.stopLocationFlag changes
        calculateDistance(
            bookingDetails.location.fromPlaceId,
            bookingDetails.location.toPlaceId,
            bookingDetails.location.stopPlaceId,
            bookingDetails.location.stopPlaceId2,
            bookingDetails.location.stopPlaceId3,
            bookingDetails.location.stopPlaceId4
        );

    }, [bookingDetails.flagForDistanceCalculation]);

    const calculateDistance = async (fromPlaceId, toPlaceId, stopPlaceId, stopPlaceId2, stopPlaceId3, stopPlaceId4) => {

        const placeIds = [
            fromPlaceId, // start point
            bookingDetails.location.stopLocationFlag && stopPlaceId, // stop 1 if flag is true
            bookingDetails.location.stopLocationFlag2 && stopPlaceId2, // stop 2 if flag is true
            bookingDetails.location.stopLocationFlag3 && stopPlaceId3, // stop 3 if flag is true
            bookingDetails.location.stopLocationFlag4 && stopPlaceId4, // stop 4 if flag is true
            toPlaceId     // end point
        ].filter(Boolean);

        let totalDistance = 0;
        let totalDuration = 0;
        let distanceText = '';
        let durationText = '';

        // Loop through the placeIds and calculate the distance between each consecutive pair
        for (let i = 0; i < placeIds.length - 1; i++) {
            const distanceElement = await MapDataService.getDistanceMatrixData(placeIds[i], placeIds[i + 1]);
            totalDistance += distanceElement.distance.value;
            totalDuration += distanceElement.duration.value;

            // Update the text representation (using the first leg for display purposes)
            if (i === 0) {
                distanceText = distanceElement.distance.text;
                durationText = distanceElement.duration.text;
            }
        }

        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            distanceMatrix: {
                changeFlag: !bookingDetails.distanceMatrix.changeFlag, // Toggle the change flag to trigger updates
                distance: {
                    text: distanceText, // Using the first segment's text
                    value: totalDistance // Total distance value
                },
                duration: {
                    text: durationText, // Using the first segment's text
                    value: totalDuration // Total duration value
                }
            }
        }));

    }

    const handleBookingDateChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                bookingDate: event.target.value
            }
        }));
    };

    const handleBookingTimeChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                bookingTime: event.target.value
            }
        }));
    };

    const handlePassengersCountChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                passengersCount: event.target.value
            }
        }));
    };

    const handleByTheHourCheckChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                byTheHourCheck: event.target.checked,
                numberOfHours: 0
            }
        }));

        if (!event.target.checked) {
            setBookingDetails((prevDetails) => ({
                ...prevDetails,
                distanceMatrix: {
                    ...prevDetails.distanceMatrix,
                    changeFlag: !bookingDetails.distanceMatrix.changeFlag
                }
            }));

        }
    };

    const increment = (e) => {
        e.preventDefault();
        let hours = bookingDetails.generalDetails.numberOfHours + 1;
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                numberOfHours: hours
            }
        }));
    }

    const decrement = (e) => {
        e.preventDefault();
        let hours = bookingDetails.generalDetails.numberOfHours - 0.5;
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                numberOfHours: hours
            }
        }));
    }

    const handleStopLocationFlagChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocationFlag: event.target.checked,
                stopLocation: '',
                stopPlaceId: '',
                flagForStopLocation2: true
            }
        }));
    };

    const handleStopLocationFlagChange2 = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocationFlag2: event.target.checked,
                stopLocation2: '',
                stopPlaceId2: '',
                flagForStopLocation3: true
            }
        }));
    };

    const handleStopLocationFlagChange3 = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocationFlag3: event.target.checked,
                stopLocation3: '',
                stopPlaceId3: '',
                flagForStopLocation4: true
            }
        }));
    };

    const handleStopLocationFlagChange4 = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            location: {
                ...prevDetails.location,
                stopLocationFlag4: event.target.checked,
                stopLocation4: '',
                stopPlaceId4: ''
            }
        }));
    };

    const handleExpectedEndTimeChange = (event) => {
        setBookingDetails((prevDetails) => ({
            ...prevDetails,
            generalDetails: {
                ...prevDetails.generalDetails,
                expectedEndTime: event.target.value
            }
        }));
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <fieldset className="step" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="container">
                <div className="row grid-view gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">
                    <div className="col-md-6">
                        <div className="location-header">
                            <i className="uil uil-map-marker"></i> <span>FROM</span>
                        </div>
                        <div className="form-floating mb-5">
                            <input type="text" className="form-control" placeholder="Enter a location (for airports type e.g. “Sydney Airport”, “Melbourne Airport”, “Perth Airport” etc)"
                                id="pickupLocation" value={bookingDetails.location.pickupLocation} onChange={handleInputChange} />
                            {dropdownVisible && autoCompleteResults.length > 0 && (
                                <div ref={suggestionFromContainerRef} className="suggestion-container">
                                    {autoCompleteResults.map((prediction) => (
                                        <div key={prediction.place_id}
                                            onClick={() => handleAddressSelection(prediction.place_id, prediction.description)}
                                            className="suggestion-item">
                                            {prediction.description}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <label htmlFor="pickupLocation">Pickup location</label>
                            {bookingErrors.fromPlaceId && (
                                <div class="text-danger"> {bookingErrors.fromPlaceId}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="location-header">
                            <i className="uil uil-map-marker"></i> <span>TO</span>
                        </div>
                        <div className="form-floating mb-5">
                            <input type="text" className="form-control"
                                placeholder="Enter a location (for airports type e.g. “Sydney Airport”, “Melbourne Airport”, “Perth Airport” etc)"
                                id="dropLocation" value={bookingDetails.location.dropLocation} onChange={handleInputChangeForToLocation} />
                            {dropdownVisibleForTo && autoCompleteResultsForTo.length > 0 && (
                                <div ref={suggestionToContainerRef} className="suggestion-container">
                                    {autoCompleteResultsForTo.map((prediction) => (
                                        <div key={prediction.place_id}
                                            onClick={() => handleAddressSelectionForToLocation(prediction.place_id, prediction.description)}
                                            className="suggestion-item">
                                            {prediction.description}
                                        </div>
                                    ))}
                                </div>
                            )}
                            <label htmlFor="dropLocation">Drop location</label>
                            {bookingErrors.toPlaceId && (
                                <div class="text-danger"> {bookingErrors.toPlaceId}</div>
                            )}
                        </div>
                    </div>
                    {/*
                    <div className="col-12">
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input" type="checkbox" role="switch"
                                id="addanotherdestination" />
                            <label className="form-check-label" htmlFor="addanotherdestination">Add another
                                destination?</label>
                        </div>
                    </div>
                    <div id="extradestination" className="col-md-4">
                        <div className="form-floating mb-5">
                            <input type="text" className="form-control" placeholder="Another Destination"
                                id="anotherdestination" />
                            <label htmlFor="anotherdestination">Another Destination</label>
                        </div>
                    </div>

                            */}
                    <div className="col-md-6">
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input" type="checkbox" role="switch"
                                id="addanotherdestination"
                                checked={bookingDetails.location.stopLocationFlag}
                                onChange={handleStopLocationFlagChange}
                            />
                            <label className="form-check-label" htmlFor="addanotherdestination">Add a stop?</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {bookingDetails.location.stopLocationFlag && (


                            <div className="form-floating mb-5">
                                <input type="text" className="form-control"
                                    placeholder="Enter a location"
                                    id="stopLocation" value={bookingDetails.location.stopLocation} onChange={handleInputChangeForStopLocation} />
                                {dropdownVisibleForStop && autoCompleteResultsForStop.length > 0 && (
                                    <div ref={suggestionStopContainerRef} className="suggestion-container">
                                        {autoCompleteResultsForStop.map((prediction) => (
                                            <div key={prediction.place_id}
                                                onClick={() => handleAddressSelectionForStopLocation(prediction.place_id, prediction.description)}
                                                className="suggestion-item">
                                                {prediction.description}
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <label htmlFor="stopLocation">Enter a stop location</label>
                                {bookingErrors.stopPlaceId && (
                                    <div class="text-danger"> {bookingErrors.stopPlaceId}</div>
                                )}
                            </div>

                        )}
                    </div>

                    {/* *                                */}
                    {bookingDetails.location.flagForStopLocation2 && (
                        <>
                            <div className="col-md-6">
                                <div className="form-check form-switch mb-5">
                                    <input className="form-check-input" type="checkbox" role="switch"
                                        id="addanotherdestination2"
                                        checked={bookingDetails.location.stopLocationFlag2}
                                        onChange={handleStopLocationFlagChange2}
                                    />
                                    <label className="form-check-label" htmlFor="addanotherdestination2">Add another stop?</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {bookingDetails.location.stopLocationFlag2 && (


                                    <div className="form-floating mb-5">
                                        <input type="text" className="form-control"
                                            placeholder="Enter a location"
                                            id="stopLocation2" value={bookingDetails.location.stopLocation2} onChange={handleInputChangeForStopLocation2} />
                                        {dropdownVisibleForStop2 && autoCompleteResultsForStop2.length > 0 && (
                                            <div ref={suggestionStopContainerRef2} className="suggestion-container">
                                                {autoCompleteResultsForStop2.map((prediction) => (
                                                    <div key={prediction.place_id}
                                                        onClick={() => handleAddressSelectionForStopLocation2(prediction.place_id, prediction.description)}
                                                        className="suggestion-item">
                                                        {prediction.description}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <label htmlFor="stopLocation2">Enter a stop location</label>
                                        {bookingErrors.stopPlaceId2 && (
                                            <div class="text-danger"> {bookingErrors.stopPlaceId2}</div>
                                        )}
                                    </div>

                                )}
                            </div>
                        </>
                    )}

                    {bookingDetails.location.flagForStopLocation3 && (
                        <>
                            <div className="col-md-6">
                                <div className="form-check form-switch mb-5">
                                    <input className="form-check-input" type="checkbox" role="switch"
                                        id="addanotherdestination3"
                                        checked={bookingDetails.location.stopLocationFlag3}
                                        onChange={handleStopLocationFlagChange3}
                                    />
                                    <label className="form-check-label" htmlFor="addanotherdestination3">Add another stop?</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {bookingDetails.location.stopLocationFlag3 && (


                                    <div className="form-floating mb-5">
                                        <input type="text" className="form-control"
                                            placeholder="Enter a location"
                                            id="stopLocation3" value={bookingDetails.location.stopLocation3} onChange={handleInputChangeForStopLocation3} />
                                        {dropdownVisibleForStop3 && autoCompleteResultsForStop3.length > 0 && (
                                            <div ref={suggestionStopContainerRef3} className="suggestion-container">
                                                {autoCompleteResultsForStop3.map((prediction) => (
                                                    <div key={prediction.place_id}
                                                        onClick={() => handleAddressSelectionForStopLocation3(prediction.place_id, prediction.description)}
                                                        className="suggestion-item">
                                                        {prediction.description}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <label htmlFor="stopLocation3">Enter a stop location</label>
                                        {bookingErrors.stopPlaceId3 && (
                                            <div class="text-danger"> {bookingErrors.stopPlaceId3}</div>
                                        )}
                                    </div>

                                )}
                            </div>
                        </>
                    )}

                    {bookingDetails.location.flagForStopLocation4 && (
                        <>
                            <div className="col-md-6">
                                <div className="form-check form-switch mb-5">
                                    <input className="form-check-input" type="checkbox" role="switch"
                                        id="addanotherdestination4"
                                        checked={bookingDetails.location.stopLocationFlag4}
                                        onChange={handleStopLocationFlagChange4}
                                    />
                                    <label className="form-check-label" htmlFor="addanotherdestination4">Add another stop?</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {bookingDetails.location.stopLocationFlag4 && (


                                    <div className="form-floating mb-5">
                                        <input type="text" className="form-control"
                                            placeholder="Enter a location"
                                            id="stopLocation4" value={bookingDetails.location.stopLocation4} onChange={handleInputChangeForStopLocation4} />
                                        {dropdownVisibleForStop4 && autoCompleteResultsForStop4.length > 0 && (
                                            <div ref={suggestionStopContainerRef4} className="suggestion-container">
                                                {autoCompleteResultsForStop4.map((prediction) => (
                                                    <div key={prediction.place_id}
                                                        onClick={() => handleAddressSelectionForStopLocation4(prediction.place_id, prediction.description)}
                                                        className="suggestion-item">
                                                        {prediction.description}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <label htmlFor="stopLocation4">Enter a stop location</label>
                                        {bookingErrors.stopPlaceId4 && (
                                            <div class="text-danger"> {bookingErrors.stopPlaceId4}</div>
                                        )}
                                    </div>

                                )}
                            </div>
                        </>
                    )}

                    {/* *                                */}

                    <div className="col-md-4">
                        <div className="form-floating mb-5">
                            <input type="date" className="form-control" placeholder="Enter date" id="bookingDate"
                                value={bookingDetails.generalDetails.bookingDate}
                                min={today}
                                onClick={(e) => e.target.showPicker()}
                                onChange={handleBookingDateChange} />
                            <label htmlFor="bookingDate">Enter date</label>
                            {bookingErrors.bookingDate && (
                                <div class="text-danger"> {bookingErrors.bookingDate}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">

                        <div className="form-floating mb-5">
                            <input type="time"
                                className="form-control"
                                placeholder="Pickup time"
                                id="bookingTime"
                                value={bookingDetails.generalDetails.bookingTime}
                                onChange={handleBookingTimeChange}
                                required />

                            <label htmlFor="bookingTime">Pickup time</label>
                            {bookingErrors.bookingTime && (
                                <div class="text-danger"> {bookingErrors.bookingTime}</div>
                            )}

                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-5">
                            <input type="number" className="form-control" placeholder="Passengers" id="passengersCount"
                                value={bookingDetails.generalDetails.passengersCount}
                                onChange={handlePassengersCountChange} />
                            <label htmlFor="passengersCount">Passengers</label>
                            {bookingErrors.passengersCount && (
                                <div class="text-danger"> {bookingErrors.passengersCount}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input" type="checkbox" role="switch" id="bythehour"
                                checked={bookingDetails.generalDetails.byTheHourCheck}
                                onChange={handleByTheHourCheckChange} />
                            <label className="form-check-label" htmlFor="bythehour">By the Hour</label>
                        </div>
                    </div>
                    {bookingDetails.generalDetails.byTheHourCheck && (

                        <>
                            {/*  <div id="extratime" className="col-md-4">
                                <div className="form-floating mb-5">
                                    <input type="time" className="form-control" placeholder="Expected End Time"
                                        id="expectedEndTime"
                                        value={bookingDetails.generalDetails.expectedEndTime}
                                        onChange={handleExpectedEndTimeChange} />
                                    <label htmlFor="expectedEndTime">Expected End Time</label>
                                </div>
                            </div>
                            */}

                            <div className="row align-items-center">
                                <label htmlFor="counterValue" className="col-auto fw-bold">Number of Hours:</label>

                                <div className="col-auto">
                                    <div className="btn-group">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={decrement}
                                            disabled={bookingDetails.generalDetails.numberOfHours <= 0}
                                        >
                                            -
                                        </button>
                                        <span className="btn btn-outline-secondary btn-sm text-dark">{bookingDetails.generalDetails.numberOfHours}</span>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={increment}
                                        >
                                            +
                                        </button>
                                    </div>
                                    {bookingErrors.numberOfHours && (
                                        <div class="text-danger"> {bookingErrors.numberOfHours}</div>
                                    )}
                                </div>
                            </div>
                        </>

                    )}
                </div>

                <div className="col-md-6">
                    <div className="location-header">
                        <i></i> <span>Booking Notes</span>
                    </div>
                    <div className="form-floating mb-5">
                        <textarea
                            className="form-control"
                            placeholder="Enter any special instructions (e.g., specific pickup instructions, preferences)"
                            value={bookingDetails.generalDetails.specialInstructions}  // Bind the value to bookingDetails
                            onChange={(e) => setBookingDetails({
                                ...bookingDetails,
                                generalDetails: {
                                    ...bookingDetails.generalDetails,
                                    specialInstructions: e.target.value,  // Update the value when it changes
                                },
                            })}
                            maxLength="30"
                            style={{ height: '100px' }}  // Optional: Control the height of the textarea
                        ></textarea>
                        <label htmlFor="specialInstructions">Booking Special Instructions (Max 300 characters)</label>
                        {bookingErrors.specialInstructions && (
                            <div className="text-danger">{bookingErrors.specialInstructions}</div>
                        )}
                    </div>
                </div>


            </div>
        </fieldset>
    );
}

export default BookingDetails;