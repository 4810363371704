import React from 'react';
import HeroChildSafetyPolicy from './hero-child-safety-policy';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import ChildSafetyPolicy from './child-safety-policy';


function MainChildSafetyPolicy() { 
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroChildSafetyPolicy />
            <ChildSafetyPolicy />
            <Footer />
        </div>
    );
}

export default MainChildSafetyPolicy;