import React from 'react';

const GoogleMapIframe = ({ fromPlaceId, toPlaceId }) => (
    <iframe
        width="100%"
        height="350"
        style={{ border: 0 }}
        referrerPolicy="no-referrer-when-downgrade"
        src={getMapUrl(fromPlaceId, toPlaceId)}
        allowFullScreen
        title="Google Map"></iframe>
);

function getMapUrl(fromPlaceId, toPlaceId) {
    return `https://www.google.com/maps/embed/v1/directions?key=${process.env.REACT_APP_EMBED_MAP_API_KEY}&origin=place_id:${fromPlaceId}&destination=place_id:${toPlaceId}&mode=driving`
}

export default GoogleMapIframe;