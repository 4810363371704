import React from 'react';
import Chauffeur from './chauffeur';
import Menu from '../../common/menu';
import Footer from '../../common/footer';


function MainChauffeur() {
  return (
    <div className="content-wrapper">
      <Menu />
      <Chauffeur />
      <Footer />
    </div>
  );
}

export default MainChauffeur;