import {React, useEffect, useState} from 'react';
import HeroSection from '../feature/hero/hero-section';
import Menu from './menu';
import StatsSection from '../feature/hero/stats-section';
import ServicesSection from '../feature/hero/services-section';
import StateSection from '../feature/hero/state-section';
import SectionFeatures from '../feature/hero/section-features';
import AppDownload from '../feature/hero/app-download';
import AppContact from '../feature/hero/app-contact';
import Footer from './footer';


function Home() {

  /*
  const [loadFlag, setLoadFlag] = useState(false);

  useEffect(() => {
    // Helper function to load a script
    const loadScript = (src) => {
      console.log('loadFlag value ---> ' + loadFlag);
      if(loadFlag){
          return;
      }
      console.log('Script file ---> ' + src);
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        return script;
    };

    // Load multiple scripts and manage them
      let s = loadScript('./assets/js/plugins.js');
      let s2 = loadScript('./assets/js/theme.js');
      setLoadFlag(true);
     
      return () => {
           document.body.removeChild(s);
           document.body.removeChild(s2);
      };

  }, []);
  */
  return (
    <div className="content-wrapper">
       <Menu /> 
      <HeroSection />
     {/* <StatsSection /> */}
      <ServicesSection />
     {/* <StateSection /> */}
      <SectionFeatures />
      <AppDownload />
      {/* <AppContact /> */}
      <Footer />
    </div>
  );
}

export default Home;