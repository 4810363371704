import React from 'react';


function Review() {

  const containerStyle = {
    bottom: '-0.75rem',
    right: '-0.75rem',
    width: '98%',
    height: '98%',
    zIndex: '0'
  };

  return (
    <section className="wrapper bg-light">
      <div className="container py-10 py-md-10">
        <div className="row mb-3">
          <div className="col-md-10 col-lg-12 col-xl-10 col-xxl-9 mx-auto text-center" data-cues="slideInDown"
            data-delay={process.env.REACT_APP_DELAY}>
            <h2 className="fs-16 text-uppercase text-dark mb-2">Customers Reviews</h2>
            <h3 className="display-3 mb-2">See What Our Customers Say</h3>
            <p className="fs-15"><strong>5.0</strong> rating out of 3652 reviews</p>
          </div>
        </div>
        <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
          <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="position-relative">
              <div className="shape rounded bg-soft-blue rellax d-md-block" data-rellax-speed="0"
                style={containerStyle}></div>
              <div className="card">
                <a href="#">
                  <div className="card-body px-5 py-4">
                    <img className="rounded-circle w-12 mb-4" src="./assets/img/avatars/t1.jpg"
                      srcset="./assets/img/avatars/te1@2x.jpg 2x" alt="" />
                    <h4 className="mb-1">Coriss Ambady</h4>
                    <p className="mb-4 text-dark">On time. Car very clean.</p>
                    <span className="ratings five fs-20 mb-0"></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="position-relative">
              <div className="shape rounded bg-soft-red rellax d-md-block" data-rellax-speed="0"
                style={containerStyle}></div>
              <div className="card">
                <a href="#">
                  <div className="card-body px-5 py-4">
                    <img className="rounded-circle w-12 mb-4" src="./assets/img/avatars/t2.jpg"
                      srcset="./assets/img/avatars/te2@2x.jpg 2x" alt="" />
                    <h4 className="mb-1">Cory Zamora</h4>
                    <p className="mb-4 text-dark">On time. Car very clean.</p>
                    <span className="ratings five fs-20 mb-0"></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="position-relative">
              <div className="shape rounded bg-soft-green rellax d-md-block" data-rellax-speed="0"
                style={containerStyle}></div>
              <div className="card">
                <a href="#">
                  <div className="card-body px-5 py-4">
                    <img className="rounded-circle w-12 mb-4" src="./assets/img/avatars/t3.jpg"
                      srcset="./assets/img/avatars/te3@2x.jpg 2x" alt="" />
                    <h4 className="mb-1">Nikolas Brooten</h4>
                    <p className="mb-4 text-dark">On time. Car very clean.</p>
                    <span className="ratings five fs-20 mb-0"></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="position-relative">
              <div className="shape rounded bg-soft-violet rellax d-md-block" data-rellax-speed="0"
                style={containerStyle}></div>
              <div className="card">
                <a href="#">
                  <div className="card-body px-5 py-4">
                    <img className="rounded-circle w-12 mb-4" src="./assets/img/avatars/t4.jpg"
                      srcset="./assets/img/avatars/te4@2x.jpg 2x" alt="" />
                    <h4 className="mb-1">Jackie Sanders</h4>
                    <p className="mb-4 text-dark">On time. Car very clean.</p>
                    <span className="ratings five fs-20 mb-0"></span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav className="d-flex justify-content-center mt-15" aria-label="pagination" data-cues="slideInDown"
          data-delay={process.env.REACT_APP_DELAY}>
          <ul className="pagination pagination-alt mb-0">
            <li className="page-item disabled">
              <a className="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
              </a>
            </li>
            <li className="page-item active"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item">
              <a className="page-link" href="#" aria-label="Next">
                <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}

export default Review;