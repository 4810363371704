
import React from 'react';

function BookingModalOption() {

    return (
        <div className="modal fade" id="modaloption" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-body py-4 px-6">
                        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="form-heading">
                            <h3 className="fs-30">Upgrade your Vehicle</h3>
                        </div>
                        <div id="vehicleselect" className="row gx-md-2 gy-2 isotope mb-4">
                            <div className="project item col-12">
                                <div className="card mb-4 lift overflow-hidden">
                                    <div className="card-body p-0">
                                        <div className="row justify-content-between align-items-end m-0">
                                            <div className="col-md-4 col-12 p-3 pb-0">
                                                <img src="./assets/img/photos/image 14.png" className="img-flud" />
                                            </div>
                                            <div className="col-12 col-md-8 text-primary pe-0">
                                                <div className="row justify-content-between align-items-start m-0 gx-4">
                                                    <div className="col-12 col-md-8 ps-0">
                                                        <h3 className="fs-30 pe-3 mt-3 mb-0 text-primary">Executive Sedans
                                                        </h3>
                                                        <p className="mb-3 pe-3 fs-14 fw-normal">Mercedes E className, BMW 5 Series, Genesis or
                                                            similar</p>
                                                        <p className="mb-0 pe-3 fs-14 fw-normal text-muted">Modern, sophisticated and
                                                            comprehensively equipped.</p>
                                                    </div>
                                                    <div className="col-12 col-md-4 ps-0 text-end">
                                                        <div className="fs-36 fw-bolder pe-3 mt-3 mb-0 text-primary">$ 2235.75</div>
                                                        <p className="mb-0 pe-3 fs-14 fw-normal text-muted">All prices include GST.Price does not include Govt.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 bg-primary p-0">
                                                    <span
                                                        className="d-flex justify-content-between align-items-center px-3 py-1">
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-users-alt text-secondary me-1"></i> 3 <span
                                                                className="ms-2 d-none d-xl-inline">Passengers</span>
                                                        </span>
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-briefcase-alt text-secondary me-1"></i> 2 <span
                                                                className="ms-2 d-none d-xl-inline">Suitcases</span>
                                                        </span>
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-shopping-bag text-secondary me-1"></i> 2 <span
                                                                className="ms-2 d-none d-xl-inline">Small Bags</span>
                                                        </span>
                                                        <span className="text-white">
                                                            <div className="form-check p-0">
                                                                <input id="number1" name="carnumber" type="radio" className="carnumber form-check-input d-none" required />
                                                                <label className="form-check-label btn btn-sm btn-outline-secondary rounded" htmlFor="number1">Select Vehicle</label>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project item col-12">
                                <div className="card mb-4 lift overflow-hidden">
                                    <div className="card-body p-0">
                                        <div className="row justify-content-between align-items-end m-0">
                                            <div className="col-md-4 col-12 p-3 pb-0">
                                                <img src="./assets/img/photos/image 14.png" className="img-flud" />
                                            </div>
                                            <div className="col-12 col-md-8 text-primary pe-0">
                                                <div className="row justify-content-between align-items-start m-0 gx-4">
                                                    <div className="col-12 col-md-8 ps-0">
                                                        <h3 className="fs-30 pe-3 mt-3 mb-0 text-primary">Executive Sedans
                                                        </h3>
                                                        <p className="mb-3 pe-3 fs-14 fw-normal">Mercedes E className, BMW 5 Series, Genesis or
                                                            similar</p>
                                                        <p className="mb-0 pe-3 fs-14 fw-normal text-muted">Modern, sophisticated and
                                                            comprehensively equipped.</p>
                                                    </div>
                                                    <div className="col-12 col-md-4 ps-0 text-end">
                                                        <div className="fs-36 fw-bolder pe-3 mt-3 mb-0 text-primary">$ 2235.75</div>
                                                        <p className="mb-0 pe-3 fs-14 fw-normal text-muted">All prices include GST.Price does not include Govt.</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 bg-primary p-0">
                                                    <span
                                                        className="d-flex justify-content-between align-items-center px-3 py-1">
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-users-alt text-secondary me-1"></i> 3 <span
                                                                className="ms-2 d-none d-xl-inline">Passengers</span>
                                                        </span>
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-briefcase-alt text-secondary me-1"></i> 2 <span
                                                                className="ms-2 d-none d-xl-inline">Suitcases</span>
                                                        </span>
                                                        <span className="d-flex align-items-center text-white">
                                                            <i className="uil uil-shopping-bag text-secondary me-1"></i> 2 <span
                                                                className="ms-2 d-none d-xl-inline">Small Bags</span>
                                                        </span>
                                                        <span className="text-white">
                                                            <div className="form-check p-0">
                                                                <input id="number2" name="carnumber" type="radio" className="carnumber form-check-input d-none" required />
                                                                <label className="form-check-label btn btn-sm btn-outline-secondary rounded" htmlFor="number2">Select Vehicle</label>
                                                            </div>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-auto text-center">
                            <button className="btn bg-primary text-white" data-bs-dismiss="modal" aria-label="Close">NO Thanks</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingModalOption;
