import React from 'react';

function ServicesSection() {
  return (
    <section className="wrapper bg-light">
      <div className="container pt-10 pt-md-10 pb-14 pb-md-14">
        <div className="row text-center mb-6">
          <div className="col-lg-12 col-xl-12 col-xxl-12 mx-auto position-relative" data-cues="slideInDown"
            data-delay={process.env.REACT_APP_DELAY}>
            <h2 className="fs-16 text-uppercase text-dark mb-2">WHY CHOOSE US?</h2>
            <h3 className="display-3 mb-2">At Your Service</h3>
            <p className="fs-15">When you choose Chauffeurs, you’re selecting luxury, style, comfort, and, above all,
              experience. From our amiable drivers to our impeccably appointed vehicles and thoughtful amenities like
              umbrellas, coffees, and insider knowledge, rest assured, you're in excellent hands with Chauffeurs.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12 mx-auto">
            <div className="row gx-md-8 gy-10 text-center">
             
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/car-frontal.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Full GPS Tracking<br />On Vehicles</h4>
              </div>
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/24-hours-support.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Here To Help<br />24 Hours A Day</h4>
              </div>

{/* 
 <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/driver.svg" className="w-30"
                    alt="" /> </div>
                <h4 className="fs-20">Australia’s Largest<br />Chauffeur Service</h4>
              </div>
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/work-experience.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Over 30+<br />Years of Experience</h4>
              </div>
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/driving-control.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Australia’s Largest<br />Chauffeur Service</h4>
              </div>
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/agent.svg" className="w-30"
                    alt="" /> </div>
                <h4 className="fs-20">Australia’s Largest<br />Chauffeur Service</h4>
              </div>
*/}
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/conference.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Large Events &<br />Conferences Experts</h4>
              </div>
              <div className="col-md-6 col-lg-3" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <div className="p-4 btn btn-soft-yellow mb-3"> <img src="./assets/img/icons/like-svgrepo.svg"
                    className="w-30" alt="" /> </div>
                <h4 className="fs-20">Courteous & Reliable<br />Chauffeurs</h4>
              </div>
              
            </div>
          </div>
          </div>
      </div>
    </section>
  );
}

export default ServicesSection;
