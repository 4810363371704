import {React, useEffect} from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroServices from './hero-services';
import GroundTransport from '../general/ground-transport';
import WhyChooseUs from '../general/why-choose-us';


function MainServices() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroServices />
      <GroundTransport />
      <WhyChooseUs />
      <Footer />
    </div>
  );
}

export default MainServices;