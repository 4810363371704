import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { BookAndManageButton } from '../../common/genericComponent';

function HeroSection() {
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Function to handle clicks
  const handleClick = (store) => {
    setMessage(`The ${store} is currently under development and will be available soon!`);
    setShowModal(true); // Show the modal
  };

  const handleClose = () => setShowModal(false);
  return (
    <>
      <section className="wrapper gradient-2 text-white">
        <div className="container py-lg-12 pt-12 pb-sm-22 pb-20 position-relative">
          <div className="row gx-0 align-items-center">
            <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 mt-lg-n2 text-start order-2 order-lg-0"
              data-cues="slideInDown" data-group="page-title" data-delay={process.env.REACT_APP_DELAY}>
              <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0 text-white">Business Class Chauffeur</h1>
              <p className="fs-15 mb-4">Discover Chauffeurs: Premium transfers, tours, events. Luxury,
                reliability, excellence guaranteed.</p>
              <div className="d-flex justify-content-start mb-5 gap-2 flex-column flex-md-row"
                data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <BookAndManageButton />
              </div>
              <div className="d-flex flex-row justify-content-md-start justify-content-center gap-1" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                <a href="#" onClick={() => handleClick('App Store')}>
                  <img src="./assets/img/photos/button-appstore.svg" className="w-100 h-11 rounded" alt="" />
                </a>
                <a href="#" onClick={() => handleClick('Google Play Store')}>
                  <img src="./assets/img/photos/button-google-play.svg" className="w-100 h-11 rounded" alt="" />
                </a>
              </div>
            </div>
          </div>
          <img className="position-absolute banner-img col-10 col-sm-12 col-lg-7" src="./assets/img/photos/cars.png"
            srcSet="./assets/img/photos/cars@2x.png 2x" data-cue="fadeIn" data-delay={process.env.REACT_APP_DELAY} alt="" />
        </div>
             {/* Modal component */}
        <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Work in Progress</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
}

export default HeroSection;
