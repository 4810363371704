import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroReview from './hero-review';
import Review from './review';


function MainReview() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroReview />
      <Review />
      <Footer />
      
    </div>
  );
}

export default MainReview    ;