import React from 'react';
import FeedbackContact from './feedback-contact';
import HeroContact from './hero-contact';
import Menu from '../common/menu';
import Footer from '../common/footer';


function MainContact() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroContact />
      <FeedbackContact />
      <Footer />
      
    </div>
  );
}

export default MainContact;