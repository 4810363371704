import {React, useEffect} from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroFAQs from './hero-faqs';
import ListFAQs from './list-faqs';


function MainFAQs() {
  return (
    <div className="content-wrapper">
      <Menu /> 
      <HeroFAQs />
      <ListFAQs />
      <Footer />
    </div>
  );
}

export default MainFAQs;