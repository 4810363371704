import React, {useContext} from 'react';
import { BookingContext } from '../../../contexts/bookingContext';
import Payment from './payment';

function BookingPaymentSummary() {
    let { bookingDetails, setBookingDetails } = useContext(BookingContext);

    const handlePromoCode = (e) => {
        e.preventDefault();
    }
 
    return (
        <fieldset className="step" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
            <div className="container">
                <div className="row align-items-end gx-md-8 gx-xl-10 gy-6 gy-lg-0 mb-5">
                    <div className="col-md-6">
                        <div className="form-heading">
                            <h3>Order Summary</h3>
                        </div>
                        <div className="row align-items-end gx-md-2 gx-xl-3 gy-2 gy-lg-0 mb-4">
                            <div className="col-md-10">
                                <label htmlFor="code" className="text-muted mb-2 fw-normal">Have a promo code ?</label>
                                <input type="text" className="form-control promocode" id="code" placeholder="Enter promo code" />
                            </div>
                            <div className="col-md-2">
                                <button type="submit" className="btn btn-primary apply" onClick={handlePromoCode}>Apply</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-order">
                                <tbody>
                                    {/*
                                    <tr>
                                        <td className=""><strong className="text-dark">Subtotal :</strong></td>
                                        <td className="text-end">
                                            <p className="price">$ 359.96</p>
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <td className=""><strong className="text-dark">Discount <span className="text-muted fw-normal">(VELZON15)</span> :</strong></td>
                                        <td className="text-end">
                                            <p className="price">- $ 53.99</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=""><strong className="text-dark">Toll :</strong></td>
                                        <td className="text-end">
                                            <p className="price">$ 65.00</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className=""><strong className="text-dark">Estimated Tax (12.5%) :</strong></td>
                                        <td className="text-end">
                                            <p className="price">$ 44.99</p>
                                        </td>
                                    </tr>
                                    */}
                                    <tr className="bg-gray">
                                        <td className=""><strong className="text-dark">Total (AUD) :</strong></td>
                                        <td className="text-end">
                                            <p className="price text-dark fw-bold">${bookingDetails.bookingFee}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-md-6">
                       {/* <img src="./assets/img/photos/cars.png" className="img-flud" /> */}
                    </div>
                    <div className="col-12">
                        <div className="form-heading">
                        {bookingDetails.bookingStatus !== 'CONFIRMED' && <h3>Payment Option</h3>}
                        </div>
                    </div>
                    <div className="col-12">
                        <div id="paymentoption" className="d-flex justify-content-start align-items-center mb-4 gap-1">
                            <Payment />
                        </div>
                    </div>


{/*
                 <div className="col-12">
                        <div id="paymentoption1" className="d-flex justify-content-start align-items-center mb-4 gap-1">
                            <div className="form-check p-0">
                                <label className="form-check-label btn-sm btn btn-primary" htmlFor="credit">Credit card</label>
                            </div>
                            <div className="form-check p-0">
                                <label className="form-check-label btn-sm btn btn-primary" htmlFor="debit">Debit card</label>
                            </div>
                            <div className="form-check p-0">
                                <label className="form-check-label btn-sm btn btn-primary" htmlFor="paypal">PayPal</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Credit Holder Name" id="holdername" />
                            <label htmlFor="holdername">Credit Holder Name</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Credit card number" id="cardnumber" />
                            <label htmlFor="cardnumber">Credit card number</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Expiration date" id="Expirdate" />
                            <label htmlFor="Expirdate">Expiration date</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control" placeholder="Expiration Year" id="Expiryear" />
                            <label htmlFor="Expiryear">Expiration Year</label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-floating mb-4">
                            <input type="number" className="form-control" placeholder="CVV" id="cvvnumber" />
                            <label htmlFor="cvvnumber">CVV</label>
                        </div>
                    </div>
    */}
                </div>
            </div>
        </fieldset>
    );
}

export default BookingPaymentSummary;