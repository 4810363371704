import React from 'react';

const DataRetentionAndPrivacyPolicy = () => {

    return (
        <div className="container">
            <p />
            <h3>Data Retention And Privacy Policy</h3>
            <p>
                At Business Class Chauffeur (BCC), we adhere to the Privacy Act 1988 (Cth) (Privacy Act). This policy outlines how we manage the personal information we collect, hold, use, and disclose, as well as how you can contact us with any further questions.
            </p>
            <p>
            This Privacy Policy is applicable to you only to the extent that the collection and handling of your personal information by BCC is governed by the Privacy Act.
            </p>
            <div className="row">
                <div className="col-xl-10 order-xl-2">
                    <section id="snippet-1" className="wrapper pt-0">
                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="info-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#info-collapse-0"
                                        aria-expanded="true" aria-controls="info-collapse-0"> What is Personal Information? </button>
                                </div>
                                <div id="info-collapse-0" className="accordion-collapse collapse" aria-labelledby="info-0">
                                    <div className="card-body">
                                        <p>Personal information refers to any information or opinion related to an individual that can reasonably identify them, regardless of whether the information is accurate or recorded in a tangible form. This includes details such as your name, age, gender, contact information, health status, and ethnicity (with health and ethnicity classified as sensitive information). In this Privacy Policy, references to personal information also encompass sensitive information.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="types-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#types-collapse-0"
                                        aria-expanded="true" aria-controls="types-collapse-0"> Types of Personal Information We Collect and Hold </button>
                                </div>
                                <div id="types-collapse-0" className="accordion-collapse collapse" aria-labelledby="types-0">
                                    <div className="card-body">
                                        <p>We will only gather personal information from you through lawful and fair means, avoiding any unreasonable intrusion.</p>
                                        <p>The personal information we may collect includes contact details (such as your name, email address, and mobile number), photographs, employment history, educational qualifications, Commonwealth identifiers (like apprenticeship numbers), and financial information (including credit card details). Additionally, we may collect sensitive information, which can encompass details about your health, race, marital status, medical history, and criminal background.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="collect-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collect-collapse-0"
                                        aria-expanded="true" aria-controls="collect-collapse-0"> How We Collect Personal Information </button>
                                </div>
                                <div id="collect-collapse-0" className="accordion-collapse collapse" aria-labelledby="collect-0">
                                    <div className="card-body">
                                        <p>We may gather personal information from you through various means, such as in-person interactions, phone conversations, online forms or portals, and email. Additionally, we may obtain personal information from third parties, including list purchases, regional chambers, employers, former employers, referees, contract partners, medical providers, insurers, and government agencies. When we collect information from these third parties, it may be used for several purposes, such as during the pre-employment process or to provide you with services and training.</p>
                                        <p>Whenever possible, we will inform you if your personal information has been obtained from a third party.</p>
                                        <p>We also collect data from our website, including the number of visits, visit dates, pages viewed, and navigation patterns. This information helps us enhance the user experience and maintain records.</p>
                                        <p>Occasionally, we partner with third parties to conduct online promotions. We track anonymous data on the number of clicks and impressions related to these promotions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="hold-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#hold-collapse-0"
                                        aria-expanded="true" aria-controls="hold-collapse-0"> Why We Collect, Use, and Hold Your Personal Information </button>
                                </div>
                                <div id="hold-collapse-0" className="accordion-collapse collapse" aria-labelledby="hold-0">
                                    <div className="card-body">
                                        <p>We collect, use, and store your personal information as part of the pre-employment process and to deliver our services, which include migration, membership, and business training. Additionally, we gather this information to manage our ongoing relationship with you and to carry out activities related to our operations.</p>
                                        <p>Your data may be used for the following purposes:</p>
                                        <ul>
                                            <li>When you have authorized us to do so.</li>
                                            <li>In ways that you would reasonably expect.</li>
                                            <li>To confirm your identity.</li>
                                            <li>When required, authorized, or permitted by law.</li>
                                            <li>For service administration.</li>
                                            <li>To comply with applicable laws and regulations, including government and training contracts.</li>
                                            <li>To provide you with information regarding our services.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="disclosure-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#disclosure-collapse-0"
                                        aria-expanded="true" aria-controls="disclosure-collapse-0"> Disclosure of Your Personal Information </button>
                                </div>
                                <div id="disclosure-collapse-0" className="accordion-collapse collapse" aria-labelledby="disclosure-0">
                                    <div className="card-body">
                                        <p>We will never sell your personal information to third parties.</p>
                                        <p>We may share your personal information in the following circumstances:</p>
                                        <ul>
                                            <li>When you have specifically authorized us to do so.</li>
                                            <li>With regulatory bodies and government agencies.</li>
                                            <li>As required by law or through a court order.</li>
                                        </ul>
                                        <p>Additionally, personal information may be disclosed for these purposes:</p>
                                        <ul>
                                            <li>Reporting to government entities.</li>
                                            <li>Complying with regulatory or legal obligations.</li>
                                            <li>Conducting credit checks and reporting.</li>
                                            <li>Meeting legal requirements or court orders.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="marketing-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#marketing-collapse-0"
                                        aria-expanded="true" aria-controls="marketing-collapse-0"> Marketing </button>
                                </div>
                                <div id="marketing-collapse-0" className="accordion-collapse collapse" aria-labelledby="marketing-0">
                                    <div className="card-body">
                                        <p>We may use your personal information to reach out to you (via mail, phone, text message, or email) regarding our account and bookings that we believe may be of interest to you. However, we want to assure you that your data will not be used for marketing purposes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="management-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#management-collapse-0"
                                        aria-expanded="true" aria-controls="management-collapse-0"> Management and Security of Your Personal Information </button>
                                </div>
                                <div id="management-collapse-0" className="accordion-collapse collapse" aria-labelledby="management-0">
                                    <div className="card-body">
                                        <p>We are committed to taking reasonable measures to ensure that:</p>
                                        <ul>
                                            <li>The personal information we collect, store, use, and disclose is accurate, complete, up-to-date, relevant, and not misleading.</li>
                                            <li>We protect the personal information we hold against misuse, interference, and loss, as well as unauthorized access, modification, or disclosure. This is achieved by storing it on secure servers and using a robust record management system.</li>
                                            <li>When allowed by law, we will safely destroy or permanently de-identify personal information that is no longer necessary for any purposes permitted under the Privacy Act.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="overseas-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#overseas-collapse-0"
                                        aria-expanded="true" aria-controls="overseas-collapse-0"> Do We Transfer Personal Information Overseas? </button>
                                </div>
                                <div id="overseas-collapse-0" className="accordion-collapse collapse" aria-labelledby="overseas-0">
                                    <div className="card-body">
                                        <p>We do not transfer your personal information overseas. Your data remains within Australia to ensure compliance with all necessary Data Retention and Privacy Policies.</p>
                                        <p>Incase we do need to share your information with an overseas service provider or entity, we will ensure that they adhere to all relevant Data Retention and Privacy Policies.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="accordion accordion-wrapper mt-10" id="accordion">
                            <div className="card accordion-item">
                                <div className="card-header" id="complaints-0">
                                    <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#complaints-collapse-0"
                                        aria-expanded="true" aria-controls="complaints-collapse-0"> How We Handle Complaints </button>
                                </div>
                                <div id="complaints-collapse-0" className="accordion-collapse collapse" aria-labelledby="complaints-0">
                                    <div className="card-body">
                                        <p>If you have any concerns or complaints regarding how your personal information has been collected or managed by Business Class Chauffeur, please reach out to our Data & Privacy Officer at <a href={`mailto:${process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}`} className="link-body"> {process.env.REACT_APP_BUSINESS_SUPPORT_EMAIL}</a></p>
                                        <p>Your concern will be forwarded to the appropriate complaint manager, who will review and respond to your issue within 7 days.</p>
                                        <p>We strive to resolve all complaints to your satisfaction. However, if you are not satisfied with our response, you can contact the Office of the Australian Information Commissioner for further investigation into your complaint.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    );

};


export default DataRetentionAndPrivacyPolicy;