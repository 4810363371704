import React from 'react';
import HeroDataRetentionAndPrivacyPolicy from './hero-data-retention-and-privacy-policy';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import DataRetentionAndPrivacyPolicy from './data-retention-and-privacy-policy';


function MainDataRetentionAndPrivacyPolicy() { 
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroDataRetentionAndPrivacyPolicy />
            <DataRetentionAndPrivacyPolicy />
            <Footer />
        </div>
    );
}

export default MainDataRetentionAndPrivacyPolicy;