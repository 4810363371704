import React from 'react';
import { Link } from 'react-router-dom';

const BookAndManageButton = () => {
    return (
        <>
            <span>
                <Link to="/booking" className="btn d-block btn-sm btn-secondary rounded m-0 me-md-2"> Book Now </Link>
            </span>
            <span>
                <Link to="/manage-booking" className="btn d-block btn-sm btn-light text-dark rounded m-0 me-md-2"> MANAGE BOOKING </Link>
            </span>
        </>
    );
};
const BookButton = () => {
    return (
        <Link to="/booking" className="btn btn-sm btn-secondary rounded me-2">Book Now</Link>
    );
};

export { BookAndManageButton, BookButton };