
/**
 * Checks if a value is null, undefined, or an empty string.
 * @param value - The value to check.
 * @returns True if the value is null, undefined, or an empty string; otherwise, false.
 */
export function isNullOrUndefinedOrEmpty(value) {
    if (value === null || value === undefined || value === '') {
        return true;
    }
    if (typeof value === 'string') {
        value = value.trim();
    } else if (typeof value === 'number') {
        value = value.toString().trim(); // Convert number to string
    }
    return value === '';
}

export function isValidBookingReferenceId(bookingReferenceId, minLength = 8, maxLength = 12) {
    const bookingReferenceIdRegex = /^[a-zA-Z0-9]+$/;
    return typeof bookingReferenceId === 'string' &&
        bookingReferenceId.length >= minLength &&
        bookingReferenceId.length <= maxLength &&
        bookingReferenceIdRegex.test(bookingReferenceId);
}

export function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return typeof email === 'string' && emailRegex.test(email);
}

export function convertTo12HourFormat(time, flag) {
    if (isNullOrUndefinedOrEmpty(time)) {
        return;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12; // Convert 0 hours to 12
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hour12}:${formattedMinutes} ${period}`;
}

export function isPickupTimeWithInOneHour(time, bookingDate) {
    if (isNullOrUndefinedOrEmpty(bookingDate) || isBookingDateOfFutureDate(bookingDate)) {
        return false;
    }
    const [hours, minutes] = time.split(':').map(Number);
    const selectedTime = new Date(bookingDate);
    selectedTime.setHours(hours, minutes, 0, 0);

    const now = new Date();
    const oneHourLater = new Date(now);
    oneHourLater.setHours(now.getHours() + 1);

    if (selectedTime < oneHourLater) {
        return true;
    } else {
        return false;
    }
}

const isBookingDateOfFutureDate = (bookingDate) => {
    const today = new Date();
    today.setHours(today.getHours() + 1, 0, 0, 0);

    const selectedDate = new Date(bookingDate);
    selectedDate.setHours(0, 0, 0, 0);
    const isFutureDate = selectedDate > today;

    return isFutureDate; 
}