import React from 'react';
import HeroCancellationPolicy from './hero-cancellation-policy';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import CancellationPolicy from './cancellation-policy';


function MainCancellationPolicy() { 
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroCancellationPolicy />
            <CancellationPolicy />
            <Footer />
        </div>
    );
}

export default MainCancellationPolicy;