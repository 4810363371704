import React from 'react';
import { BookButton } from '../common/genericComponent';

function WhyChooseUs() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-6 pt-md-6 pb-12 pb-md-12">
                <div className="row gy-10 gy-sm-13 gx-md-8 gx-xl-12 align-items-center">
                    <div className="col-lg-6" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <div className="row gx-md-5 gy-5">
                            <div className="col-12">
                                <figure className="rounded mx-5"><img src="./assets/img/photos/img1.jpg"
                                    srcSet="./assets/img/photos/img1.jpg 2x" alt="" /></figure>
                            </div>
                            <div className="col-md-6">
                                <figure className="rounded"><img src="./assets/img/photos/img2.jpg" srcSet="./assets/img/photos/img1.jpg 2x"
                                    alt="" /></figure>
                            </div>
                            <div className="col-md-6">
                                <figure className="rounded"><img src="./assets/img/photos/img3.jpg" srcSet="./assets/img/photos/img1.jpg 2x"
                                    alt="" /></figure>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <h2 className="fs-16 text-uppercase text-gradient gradient-1 mb-3">WHY CHOOSE US?</h2>
                        <h3 className="display-4 mb-4 me-lg-n5">At Your Service</h3>
                        <p className="mb-3">Whether you are travelling for business or pleasure, let Chauffeurs make the journey as
                            memorable as the destination. Here’s what you can expect from Chauffeurs Chauffeur Services:</p>
                        <ul className="icon-list bullet-bg bullet-soft-yellow">
                            <li><i className="uil uil-check"></i>Well-appointed and luxury vehicles.</li>
                            <li><i className="uil uil-check"></i>Professional, experienced, courteous and discreet chauffeurs.</li>
                            <li><i className="uil uil-check"></i>A reliable, on-time service with real-time tracking via the Chauffeurs
                                App.</li>
                            <li><i className="uil uil-check"></i>Exceptional attention to detail from vehicle condition and cleanliness to
                                passenger experience.</li>
                            <li><i className="uil uil-check"></i>Extensive knowledge of local roads and conditions to take the best
                                possible route to your destination.</li>
                            <li><i className="uil uil-check"></i>A stress-free and personalised experience that delivers maximum comfort.
                            </li>
                        </ul>
                        <p className="mb-3">Whether you are booking for yourself or managing the booking for a VIP passenger, we keep
                            the process simple too, with easy booking, flexible payment options and a 24-hour customer service team.
                        </p>
                        <p className="mb-3">So, make your next transfer, trip or tour a seamless, personalised and premium experience
                            with Chauffeurs.</p>
                        <BookButton />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default WhyChooseUs;