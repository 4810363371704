import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import BookingForm from './bookingForm';
import { BookingProvider } from '../../../contexts/bookingContext';


function MainBooking() {
    return (
        <div className="content-wrapper">
            <Menu />
            <BookingProvider>
                <BookingForm />
            </BookingProvider>
            <Footer />
        </div>
    );
}

export default MainBooking;